import { Column, Grid } from '@carbon/react';
import { useTranslation } from 'react-i18next';
import './NoMatchPage.scss';

const NoMatchPage = () => {
  const { t } = useTranslation();

  return (
    <Grid>
      <Column lg={16} md={8} sm={4} className="center-notification">
        <h1>{t('common.notification.invalidSurveyId')}</h1>
      </Column>
    </Grid>
  );
};

export default NoMatchPage;
