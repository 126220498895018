import React, { useState } from 'react';
import { TextInput, FormGroup, Tile, Stack, Button } from '@carbon/react';
import { useTranslation } from 'react-i18next';
import { Add, Information } from '@carbon/icons-react';

interface PowerPurchaseAgreementProps {
  handleChange: (consumption: number, emission: number) => void;
}

const PowerPurchaseAgreement: React.FC<PowerPurchaseAgreementProps> = ({ handleChange }) => {
  const { t } = useTranslation();
  const [consumption, setConsumption] = useState<string>('');
  const [emissionFactor, setEmissionFactor] = useState<string>('');

  const handleSubmit = (): void => {
    if (consumption && emissionFactor) {
      const consumptionValue = Number(consumption);
      // Add validation for consumption
      if (consumptionValue <= 0) {
        alert(t('survey.step5.validation.consumptionGreaterThanZero')); // or use your preferred error display method
        return;
      }
      const emissionValue = Number(emissionFactor);
      if (emissionValue <= 0) {
        alert(t('survey.step5.validation.emissionFactorGreaterThanZero')); // or use your preferred error display method
        return;
      }

      const totalEmissions = consumptionValue * emissionValue;

      handleChange(consumptionValue, totalEmissions);

      // Reset form after submission
      setConsumption('');
      setEmissionFactor('');
    }
  };

  const handleConsumptionChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const value = e.target.value;
    if (value === '' || /^\d*\.?\d*$/.test(value)) {
      setConsumption(value);
    }
  };

  const handleEmissionFactorChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const value = e.target.value;
    if (value === '' || /^\d*\.?\d*$/.test(value)) {
      setEmissionFactor(value);
    }
  };

  return (
    <Stack gap={6} className="mt-4">
      <Tile className="power-source-content">
        <Stack gap={4}>
          <div>
            <h5 className="mb-4">{t('survey.step5.ppa.title')}</h5>
            <p className="text-sm text-gray-600">{t('survey.step5.ppa.description')}</p>
          </div>

          <FormGroup className="mt-2">
            <Stack gap={4}>
              <TextInput
                id="ppaConsumption"
                labelText={t('survey.step5.ppa.consumptionLabel')}
                helperText={t('survey.step5.ppa.consumptionHelper')}
                value={consumption}
                onChange={handleConsumptionChange}
                type="number"
                placeholder="Enter consumption in MWh"
              />

              <TextInput
                id="ppaEmissionFactor"
                labelText={t('survey.step5.ppa.emissionFactorLabel')}
                helperText={t('survey.step5.ppa.emissionFactorHelper')}
                value={emissionFactor}
                onChange={handleEmissionFactorChange}
                type="number"
                placeholder="Enter emission factor"
              />

              <Button
                kind="primary"
                renderIcon={Add}
                onClick={handleSubmit}
                disabled={!consumption || !emissionFactor}
              >
                {t('survey.step5.grid.addConsumption')}
              </Button>
            </Stack>
          </FormGroup>
        </Stack>
      </Tile>

      <Tile className="bg-blue-50">
        <div className="flex items-center gap-2">
          <Information size={20} className="text-blue-600" />
          <h5 className="text-lg font-medium">{t('survey.step5.ppa.requirementsInfo')}</h5>
        </div>
        <ul className="list-none space-y-2 mt-2">
          {[1, 2, 3].map((i) => (
            <li key={i} className="flex items-start gap-2">
              <span className="text-blue-600 font-medium">{i}.</span>
              <span>{t(`survey.step5.ppa.requirement${i}`)}</span>
            </li>
          ))}
        </ul>
      </Tile>
    </Stack>
  );
};

export default PowerPurchaseAgreement;
