import { Theme } from '@carbon/react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.scss';
import Layout from './layouts/Layout';

// design collab

import './App.scss';
import CbamAcademy from './pages/CbamAcademy/CbamAcademy';
import SBOM from './pages/Legal/SBOM';
import NoMatchPage from './pages/NoMatchPage/NoMatchPage';
import SurveyPage from './pages/SurveyPage/SurveyPage';
import WelcomePage from './pages/WelcomePage/WelcomePage';

// OIDC
import SurveyOIDCProvider from './contexts/SurveyContext';
import PrivacyNotice from './pages/Legal/PrivacyNotice';

function App() {
  return (
    <BrowserRouter>
      <SurveyOIDCProvider>
        <Theme className="themeWrapper" theme="g10">
          <Layout>
            <Routes>
              <Route path="/">
                <Route element={<WelcomePage />} path="/" />
                <Route element={<SurveyPage />} path="submission" />
                <Route element={<NoMatchPage />} path="error" />
                <Route element={<CbamAcademy />} path="academy" />
                <Route element={<SBOM />} path="SBOM" />
                <Route element={<PrivacyNotice />} path="privacy" />
              </Route>
            </Routes>
          </Layout>
        </Theme>
      </SurveyOIDCProvider>
    </BrowserRouter>
  );
}

export default App;
