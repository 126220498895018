import React, { useState } from 'react';
import { Tile, FormGroup, TextInput, Button, Dropdown, Stack } from '@carbon/react';
import { Add } from '@carbon/icons-react';
import { useTranslation } from 'react-i18next';
import { PowerSource } from '../../../types/IndirectEmissions';

interface EnergySource {
  id: string;
  type: 'grid' | 'ppa' | 'directLink';
  subType: string;
  consumption: string;
  emissionFactor: string;
}

interface CurrentSource {
  id: string;
  type: string;
  subType: string;
  consumption: string;
  emissionFactor: string;
}

interface EnergyTypeOption {
  id: string;
  text: string;
  subTypes: Array<{ id: string; text: string }>;
}

interface DirectTechnicalLinkProps {
  onSourcesAdded: (sources: Array<Omit<PowerSource, 'unit'>>) => void;
}

const DirectTechnicalLink: React.FC<DirectTechnicalLinkProps> = ({ onSourcesAdded }) => {
  const { t } = useTranslation();
  const [currentSource, setCurrentSource] = useState<CurrentSource>({
    id: Date.now().toString(),
    type: '',
    subType: '',
    consumption: '',
    emissionFactor: '',
  });

  const energyTypeOptions: EnergyTypeOption[] = [
    {
      id: 'renewable',
      text: t('survey.step5.directLink.energyTypes.renewable'),
      subTypes: [
        { id: 'biomass', text: t('survey.step5.directLink.subTypes.biomass') },
        { id: 'solar', text: t('survey.step5.directLink.subTypes.solar') },
        { id: 'wind', text: t('survey.step5.directLink.subTypes.wind') },
        { id: 'hydro', text: t('survey.step5.directLink.subTypes.hydro') },
      ],
    },
    {
      id: 'fossil',
      text: t('survey.step5.directLink.energyTypes.fossil'),
      subTypes: [
        { id: 'coal', text: t('survey.step5.directLink.subTypes.coal') },
        { id: 'lignite', text: t('survey.step5.directLink.subTypes.lignite') },
        { id: 'naturalGas', text: t('survey.step5.directLink.subTypes.naturalGas') },
        { id: 'petrol', text: t('survey.step5.directLink.subTypes.petrol') },
      ],
    },
    {
      id: 'other',
      text: t('survey.step5.directLink.energyTypes.other'),
      subTypes: [
        { id: 'wasteHeat', text: t('survey.step5.directLink.subTypes.wasteHeat') },
        { id: 'hydrogen', text: t('survey.step5.directLink.subTypes.hydrogen') },
      ],
    },
  ];

  const handleDropdownChange = (field: 'type' | 'subType', selectedItem: any): void => {
    if (selectedItem) {
      setCurrentSource((prev) => ({
        ...prev,
        [field]: selectedItem.id,
      }));
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const { name, value } = e.target;
    setCurrentSource((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  // In DirectTechnicalLink component
  const handleAddSource = (): void => {
    if (
      currentSource.type &&
      currentSource.subType &&
      currentSource.consumption &&
      currentSource.emissionFactor
    ) {
      const consumption = Number(currentSource.consumption);
      // Add validation for consumption
      if (consumption <= 0) {
        alert(t('survey.step5.validation.consumptionGreaterThanZero')); // or use your preferred error display method
        return;
      }
      const emissionFactor = Number(currentSource.emissionFactor);
      if (emissionFactor <= 0) {
        alert(t('survey.step5.validation.emissionFactorGreaterThanZero')); // or use your preferred error display method
        return;
      }

      const emissions = consumption * emissionFactor;

      onSourcesAdded([
        {
          type: 'directLink' as const,
          energyType: currentSource.type,
          subType: currentSource.subType,
          consumption: consumption,
          emissions: emissions,
        },
      ]);

      // Reset form
      setCurrentSource({
        id: Date.now().toString(),
        type: '',
        subType: '',
        consumption: '',
        emissionFactor: '',
      });
    }
  };

  const getCurrentTypeSubTypes = () => {
    const currentType = energyTypeOptions.find((type) => type.id === currentSource.type);
    return currentType ? currentType.subTypes : [];
  };

  return (
    <Tile className="power-source-content mt-4">
      <Stack gap={4}>
        <h5>{t('survey.step5.directLink.title')}</h5>

        <FormGroup>
          <Stack gap={4}>
            <Dropdown
              id="energy-type"
              titleText={t('survey.step5.directLink.typeLabel')}
              items={energyTypeOptions}
              itemToString={(item: EnergyTypeOption | null) => (item ? item.text : '')}
              selectedItem={energyTypeOptions.find((type) => type.id === currentSource.type)}
              onChange={({ selectedItem }: { selectedItem: EnergyTypeOption | null }) =>
                handleDropdownChange('type', selectedItem)
              }
            />

            {currentSource.type && (
              <Dropdown
                id="energy-subtype"
                titleText={t('survey.step5.directLink.subtypeLabel')}
                items={getCurrentTypeSubTypes()}
                itemToString={(item: { id: string; text: string } | null) =>
                  item ? item.text : ''
                }
                selectedItem={getCurrentTypeSubTypes().find(
                  (subType) => subType.id === currentSource.subType,
                )}
                onChange={({
                  selectedItem,
                }: {
                  selectedItem: { id: string; text: string } | null;
                }) => handleDropdownChange('subType', selectedItem)}
              />
            )}

            <TextInput
              id="consumption"
              name="consumption"
              labelText={t('survey.step5.directLink.consumptionLabel')}
              helperText={t('survey.step5.directLink.consumptionHelper')}
              value={currentSource.consumption}
              onChange={handleInputChange}
              type="number"
              placeholder="Enter consumption in MWh"
            />

            <TextInput
              id="emissionFactor"
              name="emissionFactor"
              labelText={t('survey.step5.directLink.emissionFactorLabel')}
              helperText={t('survey.step5.directLink.emissionFactorHelper')}
              value={currentSource.emissionFactor}
              onChange={handleInputChange}
              type="number"
              placeholder="Enter emission factor"
            />

            <Button
              kind="primary"
              renderIcon={Add}
              onClick={handleAddSource}
              disabled={
                !currentSource.type ||
                !currentSource.subType ||
                !currentSource.consumption ||
                !currentSource.emissionFactor
              }
            >
              {t('survey.step5.grid.addConsumption')}
            </Button>
          </Stack>
        </FormGroup>
      </Stack>
    </Tile>
  );
};

export default DirectTechnicalLink;
