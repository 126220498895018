import React from 'react';
import { 
  StructuredListWrapper, 
  StructuredListHead, 
  StructuredListRow, 
  StructuredListCell, 
  StructuredListBody,
  Tag,
  Button
} from '@carbon/react';
import { TrashCan } from '@carbon/icons-react';
import { useTranslation } from 'react-i18next';
import { PowerSource } from '../../../types/IndirectEmissions';

interface EmissionsDisplayProps {
  sources: PowerSource[];
  onRemoveSource: (index: number) => void;
}

const EmissionsDisplay: React.FC<EmissionsDisplayProps> = ({ sources, onRemoveSource }) => {
  const { t } = useTranslation();

  const getSourceLabel = (source: PowerSource) => {
    switch (source.type) {
      case 'grid':
        return t('survey.step5.powerSource.grid');
      case 'ppa':
        return t('survey.step5.powerSource.ppa');
      case 'directLink':
        return source.energyType 
          ? t(`survey.step5.directLink.energyTypes.${source.energyType}`)
          : t('survey.step5.powerSource.directLink');
      default:
        return source.type;
    }
  };

  const getTagType = (source: PowerSource) => {
    switch (source.type) {
      case 'grid':
        return 'red';
      case 'ppa':
        return 'blue';
      case 'directLink':
        return source.energyType === 'renewable' ? 'green' : 'blue';
      default:
        return 'gray';
    }
  };

  const totalEmissions = sources.reduce((sum, source) => sum + (Number(source.emissions) || 0), 0);
  const totalConsumption = sources.reduce((sum, source) => sum + (Number(source.consumption) || 0), 0);

  return (
    <StructuredListWrapper className="emissions-display">
      <StructuredListHead>
        <StructuredListRow head>
          <StructuredListCell head>
            {t('survey.step5.emissions.source')}
          </StructuredListCell>
          <StructuredListCell head>
            {t('survey.step5.emissions.consumption')}
          </StructuredListCell>
          <StructuredListCell head>
            {t('survey.step5.emissions.emissions')}
          </StructuredListCell>
          <StructuredListCell head>
            {t('survey.step4.dynamicTable.button.delete')}
          </StructuredListCell>
        </StructuredListRow>
      </StructuredListHead>
      <StructuredListBody>
        {sources.map((source, index) => (
          <StructuredListRow key={index}>
            <StructuredListCell>
              <Tag type={getTagType(source)}>
                {getSourceLabel(source)}
              </Tag>
              {source.subType && ` - ${source.subType}`}
            </StructuredListCell>
            <StructuredListCell>
              {source.consumption} {source.unit}
            </StructuredListCell>
            <StructuredListCell>
              {Number(source.emissions).toFixed(2)} t CO2
            </StructuredListCell>
            <StructuredListCell>
              <Button
                kind="ghost"
                size="sm"
                renderIcon={TrashCan}
                iconDescription={t('common.buttons.discard')}
                onClick={() => onRemoveSource(index)}
                hasIconOnly
              />
            </StructuredListCell>
          </StructuredListRow>
        ))}
        <StructuredListRow>
          <StructuredListCell>
            <strong>{t('survey.step5.emissions.total')}</strong>
          </StructuredListCell>
          <StructuredListCell>
            <strong>{totalConsumption.toFixed(2)} MWh</strong>
          </StructuredListCell>
          <StructuredListCell>
            <strong>{totalEmissions.toFixed(2)} t CO2</strong>
          </StructuredListCell>
          <StructuredListCell />
        </StructuredListRow>
      </StructuredListBody>
    </StructuredListWrapper>
  );
};

export default EmissionsDisplay;