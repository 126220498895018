import {
  Accordion,
  AccordionItem,
  Button,
  ButtonSet,
  Column,
  DataTableSkeleton,
  Dropdown,
  DropdownSkeleton,
  Form,
  Grid,
  SkeletonText,
  TextInput,
} from '@carbon/react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DataTableHeader } from '../../../types/DynamicTable';
import { EmissionSource, PurchaseEmission, Route } from '../../../types/SurveySupplierInput';
import DynamicEmissionTable from '../../DynamicEmissionTable/DynamicEmissionTable';
import VideoEmbed from '../../Vimeo/Vimeo';
import { FormStateAttribute } from '../FormMultisteps';
import '../FormMultisteps.scss';
import './FormStep4.scss';
import { useChina } from '../../../hooks/useChina';
import { useTurkish } from '../../../hooks/useTurkish';

const FormStep4 = ({ formState, handleChange, prevStep, nextStep }: any) => {
  const { t } = useTranslation();
  const [directEmissionsInvalid, setDirectEmissionsInvalid] = useState<Boolean>(false);

  const [routeIsLoading, setRouteIsLoading] = useState(true);
  const [sourceStreamIsLoading, setSourceStreamIsLoading] = useState(false);
  const [RouteListInvalid, setRouteListInvalid] = useState(true);
  const [hasAttemptedNextStep, setHasAttemptedNextStep] = useState(false);
  const [videoKey, setVideoKey] = useState(0); 
  const isChina = useChina();
  const isTurkish = useTurkish();

  const headers: DataTableHeader[] = [
    { key: 'mandatory', header: '', isSortable: false },
    {
      key: 'emission_name',
      header: t('survey.step4.dynamicTable.header.emission_name'),
      isSortable: false,
    },
    { key: 'category', header: t('survey.step4.dynamicTable.header.category'), isSortable: false },
    { key: 'activity', header: t('survey.step4.dynamicTable.header.activity'), isSortable: false },
    { key: 'unit', header: t('survey.step4.dynamicTable.header.unit'), isSortable: false },
    {
      key: 'purchased',
      header: t('survey.step4.dynamicTable.header.purchased'),
      isSortable: false,
    },
  ];

  const [routesList, setRoutesList] = useState<Route[]>([]);
  const [chosenRoute, setChosenRoute] = useState<Route>();

  // Different types of Emission sources:
  const [precursorsList, setPrecursorsList] = useState<EmissionSource[]>([]);
  const [fuelGasList, setFuelGasList] = useState<EmissionSource[]>([]);
  const [otherInputList, setOtherInputList] = useState<EmissionSource[]>([]);

  // Summarize of all the chosen emission sources:
  const [finalEmissionSources, setFinalEmissionSources] = useState<EmissionSource[]>([]);

  const backendUrl = process.env.REACT_APP_BACKEND_URL;

  const validateActivities = () => {
    let isValid = true;
    for (const source of finalEmissionSources)
      if (!source.activity || source.activity <= 0) {
        isValid = false;
        break;
      }
    return isValid;
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault(); // Prevent default form submission behavior
    setHasAttemptedNextStep(true);

    // Validate if direct emissions exist
    if (formState.directEmissions.length === 0) {
      setDirectEmissionsInvalid(true);
      return; // Prevent moving to the next step
    } else {
      setDirectEmissionsInvalid(false); // Reset if valid
    }

    // Validate if a route has been chosen
    if (!chosenRoute) {
      setRouteListInvalid(true); // Display validation error
      return; // Stop execution if no route is chosen
    }

    const isValid = validateActivities();
    if (!isValid) {
      return; // Stop the form submission if not valid
    }
    setRouteListInvalid(false); // Proceed since a route is chosen

    // Filter sub survey has already been sent)
    const sendPurchasedEmissions: PurchaseEmission[] = formState.purchasedEmissions.filter(
      (purchasedEmission: PurchaseEmission) => purchasedEmission.isConfirmed,
    );

    // Filter the emissions that have "purchased" set to true and belong to the "Precursor" category and filter out already sent purchased emissions (sub survey)
    const purchasedEmissions = finalEmissionSources.filter(
      (source) =>
        source.purchased === true &&
        source.category === 'CBAM_PRRP' &&
        !sendPurchasedEmissions.some(
          (purchasedEmission) => purchasedEmission.emission_id === source.emission_id,
        ),
    );

    handleChange({
      name: 'purchasedEmissions',
      value: [...sendPurchasedEmissions, ...purchasedEmissions],
    });

    // Check if any of the finalEmissionSources have "purchased" set to true
    const hasPurchased =
      finalEmissionSources.some((source) => source.purchased) ||
      formState.purchasedEmissions.some(
        (purchasedEmission: PurchaseEmission) => purchasedEmission.isConfirmed,
      );

    try {
      let directEmissionsValue = null;
      let indirectEmissionsValue = null;
      nextStep({ isIntermediateStep: hasPurchased });

      if (formState.calculationMethod === 'calculated') {

        directEmissionsValue = formState.directEmissions.reduce(
          (
            total: number,
            {
              activity,
              unit,
              direct_multiplier,
            }: { activity: number; unit: string; direct_multiplier: number },
          ) => total + (unit === 'kg' ? activity / 1000 : activity) * (direct_multiplier || 0),
          0,
        );

        indirectEmissionsValue = formState.directEmissions.reduce(
          (
            total: number,
            {
              activity,
              unit,
              indirect_multiplier,
            }: { activity: number; unit: string; indirect_multiplier: number },
          ) => total + (unit === 'kg' ? activity / 1000 : activity) * (indirect_multiplier || 0),
          0,
        );

        // Update the form state with direct emissions
        handleChange({ name: 'calculatedEmissionsDirect', value: directEmissionsValue });
        handleChange({
          name: 'calculatedEmissionsIndirectPrecursors',
          value: indirectEmissionsValue,
        });
      } else if (formState.calculationMethod === 'default') {
        // Call API to get total direct and indirect emissions for default calculation method
        const requestOptions = {
          method: 'GET',
          headers: { 'Content-Type': 'application/json' },
        };
        const response = await fetch(
          `${process.env.REACT_APP_BACKEND_URL}/api/emissions/default/${formState.CNNumber}`,
          requestOptions,
        );
        if (!response.ok) {
          throw new Error('Network response was not ok.');
        }
        const data = await response.json();

        // Update the form state with direct and indirect emissions from the API response
        directEmissionsValue = data.direct;
        indirectEmissionsValue = data.indirect;
        handleChange({ name: 'calculatedEmissionsDirectPerTonne', value: directEmissionsValue });
        handleChange({
          name: 'calculatedEmissionsIndirectPerTonne',
          value: indirectEmissionsValue,
        });
      }
    } catch (error) {
      console.error('Error during form submission:', error);
      // Handle errors appropriately here
    }
  };

  const fillMissingValueWithDefaultData = (emissionSource: EmissionSource) => {
    return {
      ...emissionSource,
      unit: emissionSource.unit !== undefined ? emissionSource.unit : 't',
      purchased: emissionSource.purchased !== undefined ? emissionSource.purchased : false, // Set to false by default
    };
  };

  const [emissionsRestored, setEmissionsRestored] = useState(false);

  useEffect(() => {
    if (!precursorsList || precursorsList.length === 0) {
      if (formState.route && formState.route.id) {
        setSourceStreamIsLoading(true);
        fetchSourceStreamsData(formState.route.id, false);
      }
    }
  }, []); // The empty array means no dependencies, so the effect runs once on mount.

  useEffect(() => {
    if (
      !emissionsRestored &&
      JSON.stringify(formState.directEmissions) !== JSON.stringify(finalEmissionSources) &&
      formState.directEmissions.length > finalEmissionSources.length
    ) {
      if (formState.calculationMethod !== 'default') {
        if (formState.route && formState.route.id) {
          setSourceStreamIsLoading(true);
          fetchSourceStreamsData(formState.route.id, false);
        }
        setFinalEmissionSources(formState.directEmissions);
        setEmissionsRestored(true); // Prevent future updates within this session
      }
    }
  }, [formState.directEmissions]);

  useEffect(() => {
    if (formState.route) {
      setRouteListInvalid(false);
    }
  });

  useEffect(() => {
    if (formState.route) {
      setChosenRoute(formState.route);
      setRouteListInvalid(false);
      setRouteIsLoading(false); // Set loading to false as we already have the route
      fetchRoutes(); // This function will fetch the routes
    } else {
      setRouteIsLoading(true); // Start loading only if we need to fetch routes
      fetchRoutes(); // This function will fetch the routes
    }
  }, [formState.route]);

  const fetchRoutes = async () => {
    try {
      const myHeaders = new Headers();
      myHeaders.append('accept', 'application/json');
      const requestOptions = {
        method: 'GET',
        headers: myHeaders,
      };
      const response = await fetch(
        `${backendUrl}/api/surveys/routes?cn_number=${formState.CNNumber}`,
        requestOptions,
      );
      const result = await response.json();
      setRoutesList(result.route_list);
      if (formState.route) {
        const matchingRoute = result.route_list.find(
          (route: Route) => route.id === formState.route.id,
        );
        if (matchingRoute) {
          setChosenRoute(matchingRoute);
        }
      }
    } catch (error) {
      console.error('Fetching routes failed', error);
    } finally {
      setRouteIsLoading(false); // Stop loading after fetch is complete or if there's an error
    }
  };
  const eliminateDuplicatedList = (duplicateList: EmissionSource[]) => {
    const uniqueList = Array.from(new Set(duplicateList.map((item) => item.emission_name)))
      .map((emission_name) => duplicateList.find((item) => item.emission_name === emission_name))
      .filter(Boolean) as EmissionSource[];

    return uniqueList;
  };

  useEffect(() => {
    handleDirectEmission(finalEmissionSources);
  }, [finalEmissionSources]);

  const fetchCnNumber = async (emissionSource: EmissionSource) => {
    try {
      const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
      };
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/api/surveys/goods/${emissionSource.emission_name}`,
        requestOptions,
      );
      if (!response.ok) {
        throw new Error('Network response was not ok.');
      }
      const data = await response.json();

      return data.length === 1
        ? {
            ...emissionSource,
            cn_code: data[0].cn_code,
          }
        : emissionSource;
    } catch (error) {
      console.error('Failed to fetch emission data', error);
      return emissionSource;
    }
  };

  const fetchSourceStreamsData = (route_id: number, routeChange: boolean) => {
    var myHeaders = new Headers();
    myHeaders.append('accept', 'application/json');

    var requestOptions = {
      method: 'GET',
      headers: myHeaders,
    };

    fetch(
      `${backendUrl}/api/surveys/source_streams?cn_number=${formState.CNNumber}&route_id=${route_id}`,
      requestOptions,
    )
      .then((response) => response.json())
      .then((result) => {
        let precursor_list = result.precursor_list;
        let fuel_gas_list = result.fuel_gas_list;
        let other_input_list = result.other_input_list;
        setPrecursorsList(precursor_list);
        setFuelGasList(fuel_gas_list);
        setOtherInputList(other_input_list);

        if (routeChange) {
          if (Array.isArray(precursor_list)) {
            Promise.all(
              precursor_list
                .filter((item) => item.mandatory)
                .map(fillMissingValueWithDefaultData)
                .map(fetchCnNumber)
                .flat(),
            ).then((newMandatoryList) => {
              let existingMandatoryIds = new Set(
                formState.directEmissions
                  .filter((item: { mandatory: any }) => item.mandatory)
                  .map((item: { emission_id: any }) => item.emission_id),
              );

              let updatedFinalEmissionSources = formState.directEmissions.filter(
                (item: { mandatory: any; emission_id: unknown }) =>
                  !item.mandatory || existingMandatoryIds.has(item.emission_id),
              );
              let addedMandatoryList = newMandatoryList.filter(
                (item) => !existingMandatoryIds.has(item.emission_id),
              );

              setFinalEmissionSources([...updatedFinalEmissionSources, ...addedMandatoryList]);
            });
          } else {
            console.error('precursor_list is not an array:', precursor_list);
          }
        } else {
          setFinalEmissionSources(formState.directEmissions);
        }

        setSourceStreamIsLoading(false);
      })
      .catch((error) => console.error('error', error));
  };

  const handleRoute = (event: any) => {
    const route: FormStateAttribute = {
      name: 'route',
      value: event.selectedItem,
    };
    setRouteListInvalid(false); // Reset validation state on change
    setChosenRoute(event.selectedItem);
    handleChange(route);
    if (formState.calculationMethod !== 'default') {
      setSourceStreamIsLoading(true);
      fetchSourceStreamsData(event.selectedItem.id, true);
    }
  };

  const handleDirectEmission = (emission_list: EmissionSource[]) => {
    const directEmission: FormStateAttribute = {
      name: 'directEmissions',
      value: emission_list,
    };
    if (formState.calculationMethod !== 'default') {
      handleChange(directEmission);
    }
  };

  return (
    <>
      <Form onSubmit={handleSubmit} id="step4" className="form-step">
        <div className="grid-container-element">
          <div className="grid-child-element">
            <Grid>
              <Column lg={16} md={8} sm={4}>
                <h4>{t('survey.step4.name')}</h4>
              </Column>

              <Column lg={8} md={4} sm={4}>
                <TextInput
                  name="cnNumber"
                  id="cnNumber"
                  labelText={t('survey.step3.CNNumber.labelText')}
                  helperText={t('survey.step3.CNNumber.helperText')}
                  invalidText={t('survey.step3.CNNumber.invalidText')}
                  placeholder={t('survey.step3.CNNumber.placeholder')}
                  value={formState.CNNumber}
                  readOnly
                ></TextInput>
              </Column>

              <Column lg={8} md={4} sm={4}>
                {routeIsLoading ? (
                  <>
                    <SkeletonText width="20%" />
                    <DropdownSkeleton />
                  </>
                ) : (
                  <Dropdown
                    id="route"
                    initialSelectedItem={routesList[0]}
                    titleText={t('survey.step4.route.titleText')}
                    helperText={t('survey.step4.route.helperText')}
                    invalidText={t('survey.step4.route.invalidText')}
                    label={t('survey.step4.route.labelText')}
                    selectedItem={formState.route ? chosenRoute : ''}
                    onChange={handleRoute}
                    invalid={hasAttemptedNextStep && RouteListInvalid}
                    items={routesList}
                    itemToString={(item: any) => (item ? item.name : '')}
                  />
                )}
              </Column>

              <Column lg={16} md={8} sm={4}>
                {directEmissionsInvalid && (
                  <p className="error-text">{t('survey.step4.errorText')}</p>
                )}
                {formState.calculationMethod !== 'default' &&
                  !sourceStreamIsLoading &&
                  !RouteListInvalid && (
                    <DynamicEmissionTable
                      finalEmissionSources={finalEmissionSources}
                      setFinalEmissionSources={setFinalEmissionSources}
                      PrecursorsList={precursorsList}
                      FuelGasList={fuelGasList}
                      OtherInputList={otherInputList}
                      hasAttemptedNextStep={hasAttemptedNextStep}
                    />
                  )}
                {formState.calculationMethod !== 'default' &&
                  sourceStreamIsLoading &&
                  !routeIsLoading && (
                    <DataTableSkeleton
                      headers={headers}
                      aria-label="sample table"
                      rowCount={formState.directEmissions?.length || 1}
                      showHeader={false}
                    />
                  )}
              </Column>
            </Grid>
          </div>
          <div className="grid-child-element">
            <Grid>
              <Column lg={16} md={8} sm={4}>
                <h5 className="faq">{t('survey.step4.faq.title')}</h5>
                <Accordion>
                  <AccordionItem title={t('survey.step4.faq.accordion1.title')}>
                    <p>{t('survey.step4.faq.accordion1.text')}</p>
                  </AccordionItem>
                  <AccordionItem title={t('survey.step4.faq.accordion2.title')}>
                    <p>
                      {t('survey.step4.faq.accordion2.text')}
                      <div>
                      <VideoEmbed
                        src={
                          isChina
                            ? 'https://video.ibm.com/embed/recorded/134265662'
                            : isTurkish
                            ? 'https://player.vimeo.com/video/1064210203'
                            : 'https://player.vimeo.com/video/967155093'
                        }
                        title="CBAM Academy - Direct Emissions"
                        width="100%"
                        height="300px"
                      />
                      </div>
                    </p>
                  </AccordionItem>
                  <AccordionItem title={t('survey.step4.faq.accordion3.title')}>
                    <p>{t('survey.step4.faq.accordion3.text')}</p>
                  </AccordionItem>
                </Accordion>
              </Column>
            </Grid>
          </div>
        </div>
        <Grid>
          <Column lg={16} md={8} sm={4}>
            <div className="step-btn-set-container">
              <ButtonSet className="step-btn-set">
                <Button kind="secondary" tabIndex={0} onClick={prevStep}>
                  {t('common.buttons.back')}
                </Button>
                <Button kind="primary" tabIndex={0} type="submit">
                  {t('common.buttons.next')}
                </Button>
              </ButtonSet>
            </div>
          </Column>
        </Grid>
      </Form>
    </>
  );
};

export default FormStep4;


