import { Modal, ModalBody, TextArea } from '@carbon/react';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import './RemarkDialog.scss';
import { InlineNotification } from '@carbon/react';
import { InformationFilled } from '@carbon/icons-react';

interface FormCompletionProps {
  onClose: () => void;
  onSubmitWithRemarks: (remarks: string) => void;
  comparisonStatus?: any;
  hasRenewableEnergy?: boolean;
  renewablePercentage?: number;
}

const RemarkDialog: React.FC<FormCompletionProps> = ({
  onClose,
  onSubmitWithRemarks,
  comparisonStatus,
  hasRenewableEnergy = false,
  renewablePercentage = 0
}) => {
  const { t } = useTranslation();
  const [remarks, setRemarks] = useState('');
  const MIN_REMARKS_LENGTH = 100;
  const [isSubmitting, setIsSubmitting] = useState(false);

  const isValid = remarks.trim().length >= MIN_REMARKS_LENGTH;

  // Generate a simple summary of validation issues if available
  const getValidationSummary = () => {
    if (!comparisonStatus) return null;
    
    const issues = [];
    
    if (comparisonStatus.direct?.status === 'warning') {
      const diff = comparisonStatus.direct.difference;
      issues.push(`Direct emissions ${diff > 0 ? 'higher' : 'lower'} than default by ${Math.abs(diff).toFixed(1)}%`);
    }
    
    if (comparisonStatus.indirect?.status === 'warning') {
      const diff = comparisonStatus.indirect.difference;
      issues.push(`Indirect emissions ${diff > 0 ? 'higher' : 'lower'} than default by ${Math.abs(diff).toFixed(1)}%`);
    }
    
    if (comparisonStatus.activity?.status === 'warning') {
      const diff = comparisonStatus.activity.difference;
      issues.push(`Activity ${diff > 0 ? 'higher' : 'lower'} than production by ${Math.abs(diff).toFixed(1)}%`);
    }
    
    return issues;
  };
  
  const validationIssues = getValidationSummary();

  const handleClose = () => {
    if (isSubmitting) return;
    onClose();
  };

  const handlePrimaryClick = () => {
    if (!isValid || isSubmitting) return;
    
    setIsSubmitting(true);
    try {
      onSubmitWithRemarks(remarks);
    } catch (error) {
      console.error('Error in form submission:', error);
      setIsSubmitting(false);
    }
  };

  const handleSecondaryClick = () => {
    if (isSubmitting) return; 
    onClose();
  };

  return (
    <Modal
      open={true}
      onClose={onClose}
      modalLabel={t('survey.submission.validation.errorTitle')}
      modalHeading={t('survey.submission.validation.errorSubtitle')}
      primaryButtonText="Submit"
      secondaryButtonText="Back to Survey"
      primaryButtonDisabled={!isValid}
      onRequestClose={onClose}
      onRequestSubmit={() => isValid && onSubmitWithRemarks(remarks)}
      onSecondarySubmit={handleSecondaryClick}
      // Add these additional handlers for more complete coverage
      hasScrollingContent={false}
      passiveModal={false}
    >
      <ModalBody>
        <div className="flex flex-col items-center justify-center p-4">
          {/* Add validation summary if available */}
          {validationIssues && validationIssues.length > 0 && (
            <div className="validation-summary mb-4 w-full">
              <InlineNotification
                kind="warning"
                lowContrast
                title={t('survey.submission.validation.issuesFound')}
                subtitle={
                  <div className="mt-2">
                    <ul className="list-disc pl-4">
                      {validationIssues.map((issue, index) => (
                        <li key={index} className="mb-1">{issue}</li>
                      ))}
                    </ul>
                    
                    {hasRenewableEnergy && (
                      <p className="mt-2">
                        <InformationFilled className="inline-block mr-1" size={16} />
                        {t('survey.submission.validation.renewableEnergyNote', { percentage: renewablePercentage.toFixed(1) })}
                      </p>
                    )}
                  </div>
                }
                hideCloseButton
              />
            </div>
          )}
        
          <div className="remarks-section mt-4 w-full">
            <TextArea
              labelText={t('survey.submission.modalData.remarkText')}
              value={remarks}
              onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => setRemarks(e.target.value)}
              invalid={!isValid}
              invalidText={`${t('survey.submission.modalData.remarkInvalid')} (${
                remarks.trim().length
              }/${MIN_REMARKS_LENGTH} characters minimum)`}
            />
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default RemarkDialog;