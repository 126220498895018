import { useEffect, useState } from 'react';
import i18n from '../i18n';

export type LanguageSettings = {
  language: string;
  browserLanguage: string;
};

export const useLanguage = () => {
  const [language, setLanguage] = useState<LanguageSettings>({
    language: 'en',
    browserLanguage: 'en',
  });

  useEffect(() => {
    setLanguage((prevState) => ({
      ...prevState,
      language: i18n.language,
      browserLanguage: navigator.language,
    }));
  }, [i18n.language, navigator.language]);

  return language;
};
