import React, { useState, useEffect } from 'react';
import { TextInput, FormGroup, Tile, Button, InlineLoading } from '@carbon/react';
import { useTranslation } from 'react-i18next';
import { Add } from '@carbon/icons-react';
import { PowerFromGridProps } from '../../../types/IndirectEmissions';

const PowerFromGrid: React.FC<PowerFromGridProps> = ({ handleChange, formState }) => {
  const { t } = useTranslation();
  const [consumption, setConsumption] = useState<string>('');
  const [emissionFactor, setEmissionFactor] = useState<number | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchEmissionFactor = async (): Promise<void> => {
      if (!formState.CNNumber || !formState.country?.location_id) return;

      setLoading(true);
      try {
        // First try to get the country-specific emission factor based on CN number and country code
        const intensityResponse = await fetch(
          `${process.env.REACT_APP_BACKEND_URL}/api/emissions/intensity/${formState.CNNumber}/${formState.country.country_code}`,
        );

        if (intensityResponse.ok) {
          const intensityData: { indirect_emissions: number } = await intensityResponse.json();
          setEmissionFactor(intensityData.indirect_emissions);
          setError(null);
        } else {
          // If the intensity endpoint fails, try the new electricity factor endpoint
          const locationId = formState.country.location_id;
          const electricityResponse = await fetch(
            `${process.env.REACT_APP_BACKEND_URL}/api/emissions/electricity/factor/${locationId}`,
          );

          if (electricityResponse.ok) {
            const electricityData: { value: number } = await electricityResponse.json();
            setEmissionFactor(electricityData.value);
            setError(null);
          } else {
            throw new Error(
              `Failed to get emission factor: ${intensityResponse.status}, ${
                electricityResponse ? electricityResponse.status : 'Not attempted'
              }`,
            );
          }
        }
      } catch (err) {
        setError(err instanceof Error ? err.message : 'An unknown error occurred');
        setEmissionFactor(null);
      } finally {
        setLoading(false);
      }
    };

    fetchEmissionFactor();
  }, [formState.CNNumber, formState.country?.country_code, formState.country?.location_id]);

  const handleSubmit = (): void => {
    if (consumption && emissionFactor !== null) {
      const consumptionMWh = Number(consumption);

      if (consumptionMWh <= 0) {
        alert(t('survey.step5.validation.consumptionGreaterThanZero'));
        return;
      }
      if (emissionFactor <= 0) {
        alert(t('survey.step5.validation.emissionFactorGreaterThanZero'));
        return;
      }

      const totalEmissions = consumptionMWh * emissionFactor;
      handleChange(consumptionMWh, totalEmissions);
      setConsumption('');
    }
  };

  const handleConsumptionChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const value = e.target.value;
    if (value === '' || /^\d*\.?\d*$/.test(value)) {
      setConsumption(value);
    }
  };

  return (
    <Tile className="power-source-content mt-4">
      <h5 className="mb-4">{t('survey.step5.grid.title')}</h5>

      <FormGroup>
        <TextInput
          id="gridConsumption"
          labelText={t('survey.step5.grid.consumptionLabel')}
          helperText={t('survey.step5.grid.consumptionHelper')}
          value={consumption}
          onChange={handleConsumptionChange}
          type="number"
          placeholder="Enter consumption in MWh"
        />

        {loading ? (
          <div className="mt-4">
            <InlineLoading description={t('survey.step5.grid.loading')} />
          </div>
        ) : emissionFactor !== null ? (
          <div className="mt-4">
            <p className="mb-2">
              {t('survey.step5.grid.emissionFactor')}: {emissionFactor.toFixed(2)} t CO2/MWh
            </p>
            <Button kind="primary" renderIcon={Add} onClick={handleSubmit} disabled={!consumption}>
              {t('survey.step5.grid.addConsumption')}
            </Button>
          </div>
        ) : null}

        {error && (
          <div className="mt-4 text-red-600">
            {t('survey.step5.grid.error')}: {error}
          </div>
        )}
      </FormGroup>
    </Tile>
  );
};

export default PowerFromGrid;
