import { useOidc } from '@axa-fr/react-oidc';
import { Logout } from '@carbon/icons-react';
import {
  Header,
  HeaderContainer,
  HeaderGlobalAction,
  HeaderGlobalBar,
  HeaderMenuItem,
  HeaderName,
  HeaderNavigation,
  SkipToContent,
} from '@carbon/react';
import { useLocation } from 'react-router-dom';
import { useSurvey } from '../../contexts/SurveyContext';
import LanguageSwitcher from '../MvpHeader/LanguageSwitcher/LanguageSwitcher';
import './Header.scss';

interface MvpHeaderProps {
  prefix: string;
  title: string;
  baseColor: string;
  logo: string;
}

const AppHeader = ({ baseColor, logo, prefix }: MvpHeaderProps) => {
  const { isAuthenticated, logout } = useOidc();
  const { survey } = useSurvey();

  const logoSrc = logo ? `data:image/png;base64,${logo}` : null;
  const location = useLocation();

  const isActive = (href: string) => location.pathname === href;
  const buildUrlWithSurveyId = (path: string): string => {
    return survey?.id ? `${path}?surveyId=${survey.id}` : path;
  };

  return (
    <HeaderContainer
      render={() => (
        <Header aria-label="IBM Platform Name" style={{ backgroundColor: baseColor }}>
          <SkipToContent />
          <HeaderName prefix={''}>
            {logoSrc && <img src={logoSrc} alt="Logo" className="header-logo" />}
            <p id="supplier-name">{prefix}</p>
          </HeaderName>
          <HeaderNavigation aria-label="CORA">
            <HeaderMenuItem
              href={buildUrlWithSurveyId('/')}
              isActive={isActive(buildUrlWithSurveyId('/'))}
            >
              Your CBAM Survey
            </HeaderMenuItem>
            <HeaderMenuItem href={buildUrlWithSurveyId('/academy')} isActive={isActive('/academy')}>
              CBAM Academy
            </HeaderMenuItem>
          </HeaderNavigation>
          <HeaderGlobalBar>
            <LanguageSwitcher />
            {isAuthenticated && (
              <HeaderGlobalAction
                aria-label="Logout"
                onClick={() => logout(buildUrlWithSurveyId('/'))}
              >
                <Logout size={20} style={{ fill: 'black' }} />
              </HeaderGlobalAction>
            )}
          </HeaderGlobalBar>
        </Header>
      )}
    />
  );
};

export default AppHeader;
