import { OidcConfiguration, OidcProvider } from '@axa-fr/react-oidc';
import { createContext, ReactNode, useContext, useEffect, useState } from 'react';
import { LOCAL_STORAGE_OIDC_CONFIG_NAME } from '../constants/constants';
import SessionExpiredComponent from '../oidc/SessionExpiredComponent';
import { SurveyViewData } from '../types/SurveyViewData';
import { useQueryParam } from '../hooks/useQueryParam';

interface SurveyContextType {
  survey: SurveyViewData | null;
  loading: boolean;
}

const SurveyContext = createContext<SurveyContextType | undefined>(undefined);

export default function SurveyOIDCProvider({ children }: { children: ReactNode }) {
  const surveyId = useQueryParam('surveyId');
  const testCN = useQueryParam('testCN');
  const backendUrl = process.env.REACT_APP_BACKEND_URL;

  const [survey, setSurvey] = useState<SurveyViewData | null>(null);
  const [loading, setLoading] = useState(true);

  const fetchSurvey = async () => {
    setLoading(true);
    try {
      if (surveyId) {
        const response = await fetch(`${backendUrl}/api/surveys/${surveyId}`);
        const survey: SurveyViewData = await response.json();

        setSurvey(survey);
        return;
      }

      if (testCN) {
        const response = await fetch(`${backendUrl}/api/surveys/agc/${testCN}`);
        const data = await response.json();

        const testAGC = data.agc;
        const dummyData: SurveyViewData = {
          id: '4f2869e6-9e96-4e3d-9a63-5b52d45c4103',
          cn_number: testCN !== null ? testCN : '',
          status: 'Sample Status',
          supplier_id: 'dummy_supplier_id',
          supplier_name: 'Sample Supplier Name',
          item_nrs: 'Sample Importer Item Nrs',
          supplier_item_nrs: 'Sample Supplier Item Nrs',
          order_nrs: 'Sample Order Nrs',
          aggregated_goods_category: testAGC,
          oidc_required: false,
          supplier_self_registration: false,
          base_color: '#F2F2F2',
          logo: '',
        };
        setSurvey(dummyData);

        return;
      }
    } catch (error) {
      console.error('Fehler beim Laden der Umfrage:', error);
    } finally {
      setLoading(false);
    }
  };

  function fetchOIDCConfig(surveyId: string) {
    let myHeaders = new Headers();
    myHeaders.append('accept', 'application/json');
    fetch(`${backendUrl}/api/surveys/oidc/${surveyId}`, {
      method: 'GET',
      headers: myHeaders,
    })
      .then((response) => response.json())
      .then((config) => {
        const updatedConfiguration = {
          client_id: config.client_id,
          redirect_uri: window.location.origin + '/authentication/callback',
          silent_redirect_uri: window.location.origin + '/authentication/silent-callback',
          scope: config.scope,
          authority: config.authority,
          service_worker_relative_url: config.service_worker_relative_url,
          service_worker_only: config.service_worker_only || false,
          demonstrating_proof_of_possession: config.demonstrating_proof_of_possession,
          token_renew_mode: 'id_token_invalid',
        };

        setConfiguration(updatedConfiguration);
        const oidcConfig = localStorage.getItem(LOCAL_STORAGE_OIDC_CONFIG_NAME);
        localStorage.setItem(LOCAL_STORAGE_OIDC_CONFIG_NAME, JSON.stringify(updatedConfiguration));

        if (oidcConfig !== JSON.stringify(updatedConfiguration)) {
          window.location.reload();
        }
      })
      .catch((error) => {
        console.error('Error fetching OIDC config:', error);
      });
  }

  useEffect(() => {
    if (survey?.oidc_required || survey?.supplier_self_registration) {
      fetchOIDCConfig(survey?.id);
    }
  }, [survey]);

  useEffect(() => {
    fetchSurvey();
  }, []);

  const [configuration, setConfiguration] = useState<OidcConfiguration>(
    localStorage.getItem(LOCAL_STORAGE_OIDC_CONFIG_NAME)
      ? JSON.parse(localStorage.getItem(LOCAL_STORAGE_OIDC_CONFIG_NAME) as string)
      : {
          client_id: '',
          redirect_uri: window.location.origin + '/authentication/callback',
          silent_redirect_uri: window.location.origin + '/authentication/silent-callback',
          scope: '',
          authority: '',
          service_worker_relative_url: '',
          service_worker_only: false,
          demonstrating_proof_of_possession: false,
          token_renew_mode: 'id_token_invalid',
        },
  );

  return (
    <OidcProvider configuration={configuration} sessionLostComponent={SessionExpiredComponent}>
      <SurveyContext.Provider value={{ survey, loading }}>{children}</SurveyContext.Provider>
    </OidcProvider>
  );
}

export function useSurvey() {
  const context = useContext(SurveyContext);
  if (!context) {
    throw new Error('useSurvey must be used within a SurveyProvider');
  }
  return context;
}
