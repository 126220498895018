import { Loading } from '@carbon/react';
import { useLocation, useNavigate } from 'react-router-dom';
import Footer from '../components/Footer/Footer';
import AppHeader from '../components/Header/Header';
import { useSurvey } from '../contexts/SurveyContext';
import './Layout.scss';

interface LayoutProps {
  children: React.ReactNode;
}

export default function Layout({ children }: LayoutProps) {
  const navigate = useNavigate();
  const location = useLocation();
  const className = location.pathname === '/submission' ? 'footer-sticky' : 'footer';

  const { survey, loading } = useSurvey();

  if (loading) {
    return <Loading />;
  }

  if (!survey) {
    navigate(`error`);
  }

  return (
    <div className="viewport">
      <AppHeader
        prefix={survey?.supplier_name || ''}
        title="CORA"
        baseColor={survey?.base_color || '#F2F2F2'}
        logo={survey?.logo || ''}
      />
      <div className="bodyWrapper">{children}</div>
      <Footer privacyUrl={survey?.privacy_url} className={className} />
    </div>
  );
}
