import { useNavigate, useSearchParams } from 'react-router-dom';
import { useEffect } from 'react';
import { useQueryParam } from '../hooks/useQueryParam';

const SessionExpiredComponent = () => {
  const navigate = useNavigate();

  const surveyId = useQueryParam('surveyId');

  useEffect(() => {
    navigate(`/?surveyId=${surveyId}`);
    setTimeout(() => {
      window.location.reload();
    }, 2000);
  }, [navigate, surveyId]);

  return (
    <div>
      <h1>Session expired</h1>
      <p>Redirecting in 2 seconds...</p>
    </div>
  );
};

export default SessionExpiredComponent;
