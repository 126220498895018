import React from 'react';
import { Grid, Column, Tag } from '@carbon/react';
import { CheckmarkFilled, WarningFilled } from '@carbon/react/icons';
import { useTranslation } from 'react-i18next';
import ValidationHints from '../ValidationHints/ValidationHints';
import ComparisonSection from '../ComparisonSection/ComparisonSection';
import PurchasedEmissionsNotification from '../PurchasedEmissionsNotification/PurchasedEmissionsNotification';
import ValidationSummary from '../ValidationSummary/ValidationSummary';

interface IEmission {
  category: string;
  emission_name: string;
  activity: number | string;
  unit: 't' | 'kg';
  emission_id: number;
  direct_multiplier: number;
  indirect_multiplier: number;
}

interface EmissionsComparisonProps {
  formState: any;
  comparisonStatus: any;
  defaultEmissions: any;
  emissionIntensity: any;
  hasRenewableEnergy?: boolean;
  renewablePercentage?: number;
}

// TypeScript type for status
type StatusType = 'warning' | 'success';

const EmissionsComparison: React.FC<EmissionsComparisonProps> = ({
    formState,
    comparisonStatus,
    defaultEmissions,
    emissionIntensity,
    hasRenewableEnergy = false,
    renewablePercentage = 0,
}) => {
  const { t } = useTranslation();

  // Helper function to calculate total emissions
  const calculateTotalEmissions = (emissions: IEmission[]): number => {
    return emissions
      .filter(emission => emission.category !== "CBAM_PRFG")
      .reduce((sum: number, emission: IEmission) => {
        const activityValue = Number(emission.activity);
        if (isNaN(activityValue)) {
          console.warn(`Invalid activity value: ${emission.activity}`);
          return sum;
        }
        return sum + convertToTonnes(activityValue, emission.unit);
      }, 0);
  };

  const convertToTonnes = (value: number, unit: 't' | 'kg'): number => {
    return unit === 'kg' ? value / 1000 : value;
  };

  const calculatedEmissionsDirectPerTonne = formState?.calculatedEmissionsDirectPerTonne ?? 0;
  const calculatedEmissionsIndirectPerTonne = formState?.calculatedEmissionsIndirectPerTonne ?? 0;
  const productionCalc = formState?.productionCalc ?? 0;

  const totalActivity = calculateTotalEmissions(formState.directEmissions);
  
  const activityDelta = productionCalc > 0 
    ? ((totalActivity - productionCalc) / productionCalc) * 100 
    : 0;

    const getIndirectEmissionThreshold = () => {
        // Base threshold is 20%
        let threshold = 20;
        
        // If using 100% renewable energy, set threshold to 100%
        if (renewablePercentage >= 99.9) {
          threshold = 100; // Use 100% threshold for fully renewable energy
        }
        // Otherwise, if renewable percentage is significant, increase the threshold proportionally
        else if (renewablePercentage >= 75) {
          threshold = 80; // Very high threshold for mostly renewable
        } else if (renewablePercentage >= 50) {
          threshold = 60; // High threshold for majority renewable
        } else if (renewablePercentage >= 25) {
          threshold = 40; // Medium threshold for partial renewable
        } else if (renewablePercentage > 0) {
          threshold = 30; // Slightly increased threshold for some renewable
        }
        
        return threshold;
      };

  // Calculate emission differences if not available in comparisonStatus
  const calculateEmissionDifference = (type = 'indirect') => {
    if (type === 'direct' && defaultEmissions?.direct && calculatedEmissionsDirectPerTonne !== undefined) {
      return ((calculatedEmissionsDirectPerTonne - defaultEmissions.direct) / 
        defaultEmissions.direct) * 100;
    } else if (type === 'indirect' && defaultEmissions?.indirect) {
      // Special case: if emissions are zero or near-zero and default is non-zero
      if (Math.abs(calculatedEmissionsIndirectPerTonne) < 0.001 && defaultEmissions.indirect > 0) {
        return -100; // Return -100% for a complete reduction
      }
      
      // Handle potential division by zero or very small numbers
      if (Math.abs(defaultEmissions.indirect) < 0.001) {
        return calculatedEmissionsIndirectPerTonne > 0 ? 100 : 0;
      }
      
      return ((calculatedEmissionsIndirectPerTonne - defaultEmissions.indirect) / 
        defaultEmissions.indirect) * 100;
    }
    return 0;
  };

  // Calculate intensity differences if not available in comparisonStatus
  const calculateIntensityDifference = (type = 'indirect') => {
    if (type === 'direct' && emissionIntensity?.direct_emissions && calculatedEmissionsDirectPerTonne !== undefined) {
      return ((calculatedEmissionsDirectPerTonne - emissionIntensity.direct_emissions) / 
        emissionIntensity.direct_emissions) * 100;
    } else if (type === 'indirect' && emissionIntensity?.indirect_emissions) {
      // Special case: if emissions are zero or near-zero and intensity is non-zero
      if (Math.abs(calculatedEmissionsIndirectPerTonne) < 0.001 && emissionIntensity.indirect_emissions > 0) {
        return -100; // Return -100% for a complete reduction
      }
      
      // Handle potential division by zero or very small numbers
      if (Math.abs(emissionIntensity.indirect_emissions) < 0.001) {
        return calculatedEmissionsIndirectPerTonne > 0 ? 100 : 0;
      }
      
      return ((calculatedEmissionsIndirectPerTonne - emissionIntensity.indirect_emissions) / 
        emissionIntensity.indirect_emissions) * 100;
    }
    return 0;
  };

  // Helper function to determine status based on difference
  const determineStatus = (difference: number, threshold = 20): StatusType => {
    return Math.abs(difference) > threshold ? 'warning' : 'success';
  };

  // Recalculate direct emission status
  const directEmissionStatus = React.useMemo(() => {
    // If comparisonStatus.direct is missing or difference is 0, recalculate it
    let difference = comparisonStatus?.direct?.difference;
    
    // If difference is undefined or shown as 0, recalculate it
    // Use a small epsilon to handle floating point equality
    if (difference === undefined || Math.abs(difference) < 0.001) {
      difference = calculateEmissionDifference('direct');
    }
    
    const status: StatusType = determineStatus(difference);
    
    return {
      status,
      difference: difference
    };
  }, [comparisonStatus?.direct, defaultEmissions, calculatedEmissionsDirectPerTonne]);

  // Recalculate indirect emission status based on renewable energy
  const indirectEmissionStatus = React.useMemo(() => {
    // If comparisonStatus.indirect is missing or difference is 0, recalculate it
    let difference = comparisonStatus?.indirect?.difference;
    
    // If difference is undefined or shown as 0, recalculate it
    // Use a small epsilon to handle floating point equality
    if (difference === undefined || Math.abs(difference) < 0.001) {
      difference = calculateEmissionDifference('indirect');
    }
    
    const threshold = getIndirectEmissionThreshold();
    
    // If difference is negative (lower emissions than default), it's usually good
    // but we need to check if it's within the adjusted threshold
    let status: StatusType;
    if (difference < 0) {
      // Special case for 100% reduction (zero emissions)
      if (Math.abs(difference - (-100)) < 0.1) {
        status = 'success'; // Always success for zero emissions
      } else {
        status = Math.abs(difference) > threshold ? 'warning' : 'success';
      }
    } else {
      // For positive differences (higher than reference), use standard threshold
      status = difference > 20 ? 'warning' : 'success';
    }
    
    return {
      status,
      difference: difference
    };
  }, [comparisonStatus?.indirect, defaultEmissions, calculatedEmissionsIndirectPerTonne, renewablePercentage]);

  // Indirect intensity recalculation
  const indirectIntensityStatus = React.useMemo(() => {
    let difference = comparisonStatus?.indirectIntensity?.difference;
    
    // If difference is undefined or shown as 0, recalculate it
    if (difference === undefined || Math.abs(difference) < 0.001) {
      difference = calculateIntensityDifference('indirect');
    }
    
    const threshold = getIndirectEmissionThreshold();
    
    let status: StatusType;
    if (difference < 0) {
      // Special case for 100% reduction (zero emissions)
      if (Math.abs(difference - (-100)) < 0.1) {
        status = 'success'; // Always success for zero emissions
      } else {
        status = Math.abs(difference) > threshold ? 'warning' : 'success';
      }
    } else {
      status = difference > 20 ? 'warning' : 'success';
    }
    
    return {
      status,
      difference: difference
    };
  }, [comparisonStatus?.indirectIntensity, emissionIntensity, calculatedEmissionsIndirectPerTonne, renewablePercentage]);

  return (
    <div className="emissions-comparison">
      <Grid>
        <Column sm={4} md={8} lg={16}>
          {/* User's Emissions Values */}
          <ComparisonSection
            title={t('survey.submission.validation.userEmissionValues')}
            values={[
              {
                label: t('survey.submission.validation.directEmissionLabel'),
                value: `${calculatedEmissionsDirectPerTonne.toFixed(2)} t CO2e/t`
              },
              {
                label: t('survey.submission.validation.indirectEmissionLabel'),
                value: `${calculatedEmissionsIndirectPerTonne.toFixed(2)} t CO2e/t`
              }
            ]}
            columns={2}
          />

        {/* Purchased Emissions Notification - show if purchased emissions are present */}
        {formState.purchasedEmissions && formState.purchasedEmissions.length > 0 && (
          <PurchasedEmissionsNotification 
            purchasedEmissions={formState.purchasedEmissions}
            status={formState.status}
          />
        )}

          {/* Renewable Energy Notice - show if renewable energy is present */}
          {hasRenewableEnergy && (
  <div className="renewable-energy-notice mb-4 p-4 bg-green-50 border border-green-200 rounded-md">
    <div className="flex items-center mb-2">
      <CheckmarkFilled className="text-green-600 mr-2" />
      <h5 className="text-green-800 font-medium">
        {t('survey.submission.validation.renewableEnergyDetected')}
      </h5>
    </div>
    <p className="text-sm text-green-700 mb-2">
      {t('survey.submission.validation.renewableEnergyExplanation')}
    </p>
    <div className="flex items-center">
      <div className="bg-gray-200 h-2 flex-grow rounded-full overflow-hidden">
        <div 
          className="bg-green-500 h-full" 
          style={{ width: `${renewablePercentage}%` }}
        ></div>
      </div>
    </div>   
    {/* Different messaging based on renewable percentage */}
    {renewablePercentage >= 99.9 ? (
      <p className="text-xs text-green-600 mt-1">
              {t('survey.submission.validation.renewableUse100Percent')}
      </p>
    ) : (
      <p className="text-xs text-green-600 mt-1">
        {t('survey.submission.validation.renewableThresholdExplanation', { 
          percentage: renewablePercentage.toFixed(1),
          threshold: getIndirectEmissionThreshold() 
        })}
      </p>
    )}
  </div>
)}

          {/* Default Emissions Comparison */}
          <ComparisonSection
            title={t('survey.submission.validation.defaultEmissionsLabel')}
            statusTags={[
              {
                label: t('survey.submission.validation.directEmissionLabel'),
                status: directEmissionStatus.status
              },
              {
                label: t('survey.submission.validation.indirectEmissionLabel'),
                status: indirectEmissionStatus.status
              }
            ]}
            values={[
              {
                label: t('survey.submission.validation.directEmissionLabel'),
                value: `${defaultEmissions?.direct?.toFixed(2) ?? '0.00'} t CO2e/t`,
                status: directEmissionStatus.status,
                difference: directEmissionStatus.difference
              },
              {
                label: t('survey.submission.validation.indirectEmissionLabel'),
                value: `${defaultEmissions?.indirect?.toFixed(2) ?? '0.00'} t CO2e/t`,
                status: indirectEmissionStatus.status,
                // Always show difference for zero emissions case
                difference: indirectEmissionStatus.difference
              }
            ]}
            columns={2}
          />

          {/* Country Intensity Comparison - Only show if available */}
          {emissionIntensity && (
            <ComparisonSection
              title={t('survey.submission.validation.countryIntensityLabel')}
              statusTags={[
                {
                  label: t('survey.submission.validation.directEmissionLabel'),
                  status: Math.abs(directEmissionStatus.difference) > 20 ? 'warning' : 'success'
                },
                {
                  label: t('survey.submission.validation.indirectEmissionLabel'),
                  status: indirectIntensityStatus.status
                }
              ]}
              values={[
                {
                  label: t('survey.submission.validation.directEmissionLabel'),
                  value: `${emissionIntensity?.direct_emissions?.toFixed(2) ?? '0.00'} t CO2e/t`,
                  status: Math.abs(directEmissionStatus.difference) > 20 ? 'warning' : 'success',
                  difference: directEmissionStatus.difference
                },
                {
                  label: t('survey.submission.validation.indirectEmissionLabel'),
                  value: `${emissionIntensity?.indirect_emissions?.toFixed(2) ?? '0.00'} t CO2e/t`,
                  status: indirectIntensityStatus.status,
                  // Always show difference for zero emissions case
                  difference: indirectIntensityStatus.difference
                }
              ]}
              columns={2}
            />
          )}

          {/* Activity vs Production */}
          <ComparisonSection
            title={t('survey.submission.validation.activityLevelTitle')}
            statusTags={[
              {
                label: Math.abs(activityDelta) > 20
                  ? t('survey.submission.validation.deviationHint')
                  : t('survey.submission.validation.deviationHint2'),
                status: Math.abs(activityDelta) > 20 ? 'warning' : 'success'
              }
            ]}
            values={[
              {
                label: t('survey.submission.validation.totalActivityLabel'),
                value: `${totalActivity.toFixed(2)} t`
              },
              {
                label: t('survey.submission.validation.productionTotalLabel'),
                value: `${productionCalc} t`
              },
              {
                label: t('survey.submission.validation.emissionDifference'),
                value: `${activityDelta.toFixed(1)}%`,
                status: Math.abs(activityDelta) > 20 ? 'warning' : 'success'
              }
            ]}
            columns={3}
          />

<h4>{t('survey.submission.validation.checksLabel')}</h4>
<ValidationSummary 
  comparisonStatus={{
    ...comparisonStatus,
    // Update activity status based on our corrected calculation
    activity: {
      status: Math.abs(activityDelta) > 20 ? 'warning' : 'success',
      difference: activityDelta
    },
    // Update direct emission status
    direct: directEmissionStatus,
    // Update indirect emission status based on renewable energy
    indirect: indirectEmissionStatus,
    // Only include intensity data if emissionIntensity exists
    ...(emissionIntensity ? {
      // Update direct intensity status based on direct emission difference
      directIntensity: {
        status: Math.abs(directEmissionStatus.difference) > 20 ? 'warning' : 'success',
        difference: directEmissionStatus.difference
      },
      // Update indirect intensity status
      indirectIntensity: indirectIntensityStatus
    } : {}),
    // Add renewable energy info
    renewableEnergy: {
      hasRenewable: hasRenewableEnergy,
      percentage: renewablePercentage,
      threshold: getIndirectEmissionThreshold()
    }
  }} 
  hasRenewableEnergy={hasRenewableEnergy}
  renewablePercentage={renewablePercentage}
  purchasedEmissions={formState.purchasedEmissions}
  surveyStatus={formState.status}
/>
        </Column>
      </Grid>
    </div>
  );
};

export default EmissionsComparison;