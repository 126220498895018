import { useSearchParams } from 'react-router-dom';

export const useQueryParam = (key: string) => {
  const [searchParams] = useSearchParams();

  const getParam = (key: string) => {
    return searchParams.get(
      Array.from(searchParams.keys()).find((k) => k.toLowerCase() === key.toLowerCase()) || '',
    );
  };

  return getParam(key);
};
