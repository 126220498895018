import React from 'react';
import {
  FileUploader,
  StructuredListWrapper,
  StructuredListHead,
  StructuredListBody,
  StructuredListRow,
  StructuredListCell,
  Button,
  Tag
} from '@carbon/react';
import { Download, TrashCan } from '@carbon/react/icons';
import { useTranslation } from 'react-i18next';

export interface UploadFile {
  id: number;
  category: string;
  uploadDate: string;
  file: File;
}

interface DocumentUploadProps {
  uploadFiles: UploadFile[];
  onAddFile: (category: string) => (event: React.ChangeEvent<HTMLInputElement>) => void;
  onRemoveFile: (fileId: number) => void;
}

const DocumentUpload: React.FC<DocumentUploadProps> = ({ uploadFiles, onAddFile, onRemoveFile }) => {
  const { t } = useTranslation();

  function mapCategoryName(key: string): string {
    switch (key) {
      case 'additional':
        return 'Additional Documents';
      case 'evidence':
        return 'Emissions Evidence';
      case 'methodology':
        return 'Monitoring Methodology';
      default:
        return '';
    }
  }

  return (
    <div className="details-section">
      <h4>{t('survey.step9.documents.title')}</h4>

      {/* File Upload Categories */}
      <div className="upload-categories space-y-6">
        {/* Monitoring Methodology */}
        <div className="upload-category" style={{ paddingBottom: '10px' }}>
          <h5 className="mb-2">{t('survey.step9.documents.methodologyTitle')}</h5>
          <FileUploader
            accept={['.pdf', '.doc', '.docx']}
            renderIcon={Download}
            hasIconOnly
            filenameStatus="edit"
            iconDescription="Add files"
            labelDescription={t('survey.step9.documents.methodologyDescription')}
            labelTitle=""
            multiple={true}
            onChange={onAddFile('methodology')}
            buttonKind="primary"
            size="md"
            className="file-uploader"
            buttonLabel={t('survey.step9.documents.addFilesButton')}
          />
        </div>

        {/* Emission Evidence */}
        <div className="upload-category" style={{ paddingBottom: '10px' }}>
          <h5 className="mb-2">{t('survey.step9.documents.evidenceTitle')}</h5>
          <FileUploader
            accept={['.pdf', '.xlsx', '.csv']}
            buttonKind="primary"
            filenameStatus="edit"
            iconDescription="Add files"
            labelDescription={t('survey.step9.documents.evidenceDescription')}
            multiple={true}
            onChange={onAddFile('evidence')}
            size="md"
            className="file-uploader"
            buttonLabel={t('survey.step9.documents.addFilesButton')}
          />
        </div>

        {/* Additional Documents */}
        <div className="upload-category" style={{ paddingBottom: '10px' }}>
          <h5 className="mb-2">{t('survey.step9.documents.additionalTitle')}</h5>
          <FileUploader
            accept={['.pdf', '.doc', '.docx', '.xlsx', '.csv']}
            buttonKind="primary"
            filenameStatus="edit"
            iconDescription="Add files"
            labelDescription={t('survey.step9.documents.additionalDescription')}
            labelTitle=""
            multiple={true}
            onChange={onAddFile('additional')}
            size="md"
            className="file-uploader"
            buttonLabel={t('survey.step9.documents.addFilesButton')}
          />
        </div>
      </div>

      {/* Uploaded Files List */}
      {uploadFiles.length > 0 && (
        <div className="uploaded-files-section mt-8" style={{ paddingTop: '20px' }}>
          <h5 className="mb-4">{t('survey.step9.documents.uploadedTitle')}</h5>
          <StructuredListWrapper>
            <StructuredListHead>
              <StructuredListRow head>
                <StructuredListCell head>
                  {t('survey.step9.documents.fileName')}
                </StructuredListCell>
                <StructuredListCell head>
                  {t('survey.step9.documents.category')}
                </StructuredListCell>
                <StructuredListCell head>
                  {t('survey.step9.documents.uploadDate')}
                </StructuredListCell>
                <StructuredListCell head>
                  {t('survey.step9.documents.size')}
                </StructuredListCell>
                <StructuredListCell head>
                  {t('survey.step9.documents.actions')}
                </StructuredListCell>
              </StructuredListRow>
            </StructuredListHead>
            <StructuredListBody>
              {uploadFiles.map((file) => (
                <StructuredListRow key={file.id}>
                  <StructuredListCell>{file.file.name}</StructuredListCell>
                  <StructuredListCell>
                    <Tag type="blue">{mapCategoryName(file.category)}</Tag>
                  </StructuredListCell>
                  <StructuredListCell>{file.uploadDate}</StructuredListCell>
                  <StructuredListCell>{`${(file.file.size / 1024).toFixed(
                    1,
                  )} KB`}</StructuredListCell>
                  <StructuredListCell>
                    <div className="flex gap-2">
                      <Button
                        kind="ghost"
                        size="sm"
                        renderIcon={TrashCan}
                        iconDescription="Delete"
                        hasIconOnly
                        onClick={() => onRemoveFile(file.id)}
                      />
                    </div>
                  </StructuredListCell>
                </StructuredListRow>
              ))}
            </StructuredListBody>
          </StructuredListWrapper>
        </div>
      )}
    </div>
  );
};

export default DocumentUpload;