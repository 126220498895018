import React from 'react';
import { useTranslation } from 'react-i18next';

interface ValidationStatus {
  status: string;
  difference: number;
}

interface ComparisonStatus {
  direct: ValidationStatus;
  indirect: ValidationStatus;
  activity: ValidationStatus;
  directIntensity?: ValidationStatus;
  indirectIntensity?: ValidationStatus;
}

interface ValidationHintsProps {
  comparisonStatus: ComparisonStatus;
  hasRenewableEnergy?: boolean;
  renewablePercentage?: number;
}

const ValidationHints: React.FC<ValidationHintsProps> = ({ 
  comparisonStatus, 
  hasRenewableEnergy = false,
  renewablePercentage = 0
}) => {
  const { t } = useTranslation();

  // Only show if there are any warnings
  const hasWarnings = 
    comparisonStatus.direct.status === 'warning' ||
    comparisonStatus.indirect.status === 'warning' ||
    comparisonStatus.activity.status === 'warning' ||
    comparisonStatus.directIntensity?.status === 'warning' ||
    comparisonStatus.indirectIntensity?.status === 'warning';

  if (!hasWarnings) {
    return null;
  }

  // Helper to create a hint section
  const HintSection = ({ title, items }: { title: string, items: string[] }) => (
    <div className="hint-section">
      <h5>{title}</h5>
      <ul>
        {items.map((item, index) => (
          <li key={index}>{item}</li>
        ))}
      </ul>
    </div>
  );
  
  return (
    <div className="validation-hints mt-4">
      {/* Emissions too high */}
      {((comparisonStatus.direct.status === 'warning' && comparisonStatus.direct.difference > 20) ||
        (comparisonStatus.indirect.status === 'warning' && comparisonStatus.indirect.difference > 20)) && (
        <HintSection 
          title={t('survey.submission.validation.highEmissionTitle')}
          items={[
            t('survey.submission.validation.highEmission1'),
            t('survey.submission.validation.highEmission2'),
            t('survey.submission.validation.highEmission3')
          ]}
        />
      )}

      {/* Emissions too low - Regular case without renewable energy */}
      {!hasRenewableEnergy && 
        ((comparisonStatus.direct.status === 'warning' && comparisonStatus.direct.difference < -20) ||
         (comparisonStatus.indirect.status === 'warning' && comparisonStatus.indirect.difference < -20)) && (
        <HintSection 
          title={t('survey.submission.validation.lowEmissionTitle')}
          items={[
            t('survey.submission.validation.lowEmission1'),
            t('survey.submission.validation.lowEmission2'),
            t('survey.submission.validation.lowEmission3')
          ]}
        />
      )}

      {/* Emissions too low - Renewable energy case */}
      {hasRenewableEnergy && 
        ((comparisonStatus.direct.status === 'warning' && comparisonStatus.direct.difference < -20) ||
         (comparisonStatus.indirect.status === 'warning' && comparisonStatus.indirect.difference < -20)) && (
        <HintSection 
          title={t('survey.submission.validation.renewableLowEmissionTitle')}
          items={[
            t('survey.submission.validation.renewableLowEmission1'),
            t('survey.submission.validation.renewableLowEmission2', { 
              percentage: renewablePercentage?.toFixed(1) 
            }),
            t('survey.submission.validation.renewableLowEmission3')
          ]}
        />
      )}

      {/* Intensity emissions too high */}
      {((comparisonStatus.directIntensity?.status === 'warning' && comparisonStatus.directIntensity.difference > 20) ||
        (comparisonStatus.indirectIntensity?.status === 'warning' && comparisonStatus.indirectIntensity.difference > 20)) && (
        <HintSection 
          title={t('survey.submission.validation.intensityHighTitle')}
          items={[
            t('survey.submission.validation.intensityHigh1'),
            t('survey.submission.validation.intensityHigh2'),
            t('survey.submission.validation.intensityHigh3')
          ]}
        />
      )}

      {/* Intensity emissions too low - Regular case */}
      {!hasRenewableEnergy && 
        ((comparisonStatus.directIntensity?.status === 'warning' && comparisonStatus.directIntensity.difference < -20) ||
         (comparisonStatus.indirectIntensity?.status === 'warning' && comparisonStatus.indirectIntensity.difference < -20)) && (
        <HintSection 
          title={t('survey.submission.validation.intensityLowTitle')}
          items={[
            t('survey.submission.validation.intensityLow1'),
            t('survey.submission.validation.intensityLow2'),
            t('survey.submission.validation.intensityLow3')
          ]}
        />
      )}

      {/* Intensity emissions too low - Renewable case */}
      {hasRenewableEnergy && 
        ((comparisonStatus.directIntensity?.status === 'warning' && comparisonStatus.directIntensity.difference < -20) ||
         (comparisonStatus.indirectIntensity?.status === 'warning' && comparisonStatus.indirectIntensity.difference < -20)) && (
        <HintSection 
          title={t('survey.submission.validation.renewableIntensityLowTitle')}
          items={[
            t('survey.submission.validation.renewableIntensityLow1'),
            t('survey.submission.validation.renewableIntensityLow2', { 
              percentage: renewablePercentage?.toFixed(1) 
            }),
            t('survey.submission.validation.renewableIntensityLow3')
          ]}
        />
      )}

      {/* Activity vs Production deviation */}
      {comparisonStatus.activity.status === 'warning' && (
        <div className="hint-section">
          <h5>{t('survey.submission.validation.activityLevelTitle')}</h5>
          {comparisonStatus.activity.difference > 50 ? (
            <div className="hint-content">
              <p className="hint-emphasis">
                {t('survey.submission.validation.activityLevelSubtitle')}
              </p>
              <ul>
                <li>{t('survey.submission.validation.activityLevel1')}</li>
                <li>{t('survey.submission.validation.activityLevel2')}</li>
                <li>{t('survey.submission.validation.activityLevel3')}</li>
              </ul>
            </div>
          ) : comparisonStatus.activity.difference < -20 ? (
            <div className="hint-content">
              <p className="hint-emphasis">
                {t('survey.submission.validation.activityLevelSubtitle2')}
              </p>
              <ul>
                <li>{t('survey.submission.validation.activityLevel4')}</li>
                <li>{t('survey.submission.validation.activityLevel5')}</li>
                <li>{t('survey.submission.validation.activityLevel6')}</li>
              </ul>
            </div>
          ) : null}
        </div>
      )}
    </div>
  );
};

export default ValidationHints;