import {
  Accordion,
  AccordionItem,
  Button,
  ButtonSet,
  Column,
  Dropdown,
  Form,
  Grid,
  TextInput,
} from '@carbon/react';
import { useTranslation } from 'react-i18next';
import { sortDropdown } from '../../../untils/dropdownUtil';
import VideoEmbed from '../../Vimeo/Vimeo';
import { FormStateAttribute } from '../FormMultisteps';
import '../FormMultisteps.scss';
import './FormStep8.scss';

const FormStep8 = ({ formState, handleChange, prevStep, nextStep }: any) => {
  const { t } = useTranslation();

  const cpSelectorUnsorted = [
    {
      id: 5,
      text: t('survey.step8.dropdownPaid.none'),
    },
    {
      id: 1,
      text: t('survey.step8.dropdownPaid.emissionTradingSystem'),
    },
    {
      id: 2,
      text: t('survey.step8.dropdownPaid.taxOrLevy'),
    },
    {
      id: 3,
      text: t('survey.step8.dropdownPaid.combination'),
    },
    {
      id: 4,
      text: t('survey.step8.dropdownPaid.other'),
    },
  ];

  const rebateSelectorUnsorted = [
    {
      id: 6,
      text: t('survey.step8.dropdownRebate.none'),
    },
    {
      id: 1,
      text: t('survey.step8.dropdownRebate.freeAllocation'),
    },
    {
      id: 2,
      text: t('survey.step8.dropdownRebate.financialCompensation'),
    },
    {
      id: 3,
      text: t('survey.step8.dropdownRebate.taxDeduction'),
    },
    {
      id: 4,
      text: t('survey.step8.dropdownRebate.combination'),
    },
    {
      id: 5,
      text: t('survey.step8.dropdownRebate.other'),
    },
  ];

  const handleFormOfCarbon = (event: any) => {
    const chosenElement: FormStateAttribute = {
      name: 'formOfCarbonPrice',
      value: event.selectedItem.id,
    };

    handleChange(chosenElement);
  };

  const handleFormOfRebate = (event: any) => {
    const chosenElement: FormStateAttribute = {
      name: 'formOfRebate',
      value: event.selectedItem.id,
    };

    handleChange(chosenElement);
  };

  const initialCP =
    cpSelectorUnsorted.find((item) => item.id === formState.formOfCarbonPrice) ||
    cpSelectorUnsorted[0];
  const initialRS =
    rebateSelectorUnsorted.find((item) => item.id === formState.formOfRebate) ||
    rebateSelectorUnsorted[0];

  const cpSelector = sortDropdown(cpSelectorUnsorted, (a, b) => a.text.localeCompare(b.text));
  const rebateSelector = sortDropdown(rebateSelectorUnsorted, (a, b) =>
    a.text.localeCompare(b.text),
  );

  return (
    <Form onSubmit={nextStep} id="step8" className="form-step">
      <div className="grid-container-element">
        <div className="grid-child-element">
          <Grid>
            <Column lg={16} md={8} sm={4}>
              <h4>{t('survey.step8.name')}</h4>
            </Column>
            <Column lg={8} md={4} sm={4}>
              <Dropdown
                options={cpSelector}
                name="formofCarbonPrice"
                id="formofCarbonPrice"
                initialSelectedItem={initialCP}
                items={cpSelector}
                itemToString={(item: any) => (item ? item.text : '')}
                onChange={handleFormOfCarbon}
                labelText={t('survey.step8.formofCarbonPrice.labelText')}
                helperText={t('survey.step8.formofCarbonPrice.helperText')}
                invalidText={t('survey.step8.formofCarbonPrice.invalidText')}
                placeholder={t('survey.step8.formofCarbonPrice.placeholder')}
                // required
              />
            </Column>
            <Column lg={8} md={4} sm={4}>
              <Dropdown
                options={rebateSelector}
                name="formOfRebate"
                id="formOfRebate"
                initialSelectedItem={initialRS}
                items={rebateSelector}
                itemToString={(item: any) => (item ? item.text : '')}
                onChange={handleFormOfRebate}
                labelText={t('survey.step8.formOfRebate.labelText')}
                helperText={t('survey.step8.formOfRebate.helperText')}
                invalidText={t('survey.step8.formOfRebate.invalidText')}
                placeholder={t('survey.step8.formOfRebate.placeholder')}
                // required
              />
            </Column>

            <Column lg={8} md={4} sm={4}>
              <TextInput
                name="shareCoveredPrice"
                id="shareCoveredPrice"
                labelText={t('survey.step8.shareCoveredPrice.labelText')}
                helperText={t('survey.step8.shareCoveredPrice.helperText')}
                invalidText={t('survey.step8.shareCoveredPrice.invalidText')}
                placeholder={t('survey.step8.shareCoveredPrice.placeholder')}
                // required
                value={formState.shareCoveredPrice}
                onChange={handleChange}
              />
            </Column>
            <Column lg={8} md={4} sm={4}>
              <TextInput
                name="shareCoveredRebate"
                id="shareCoveredRebate"
                labelText={t('survey.step8.shareRebate.labelText')}
                helperText={t('survey.step8.shareRebate.helperText')}
                invalidText={t('survey.step8.shareRebate.invalidText')}
                placeholder={t('survey.step8.shareRebate.placeholder')}
                // required
                value={formState.shareCoveredRebate}
                onChange={handleChange}
              />
            </Column>
          </Grid>
        </div>
        <div className="grid-child-element">
          <Grid>
            <Column lg={16} md={8} sm={4}>
              <h5 className="faq">{t('survey.step8.faq.title')}</h5>
              <Accordion>
                <AccordionItem title={t('survey.step8.faq.accordion1.title')}>
                  <p>{t('survey.step8.faq.accordion1.text')}</p>
                  <p>- {t('survey.step8.faq.accordion1.p1')}</p>
                  <p>- {t('survey.step8.faq.accordion1.p2')}</p>
                  <p>- {t('survey.step8.faq.accordion1.p3')}</p>
                  <p>- {t('survey.step8.faq.accordion1.p4')}</p>
                </AccordionItem>
                <AccordionItem title={t('survey.step8.faq.accordion2.title')}>
                  <p>
                    <div>
                      <VideoEmbed
                        src="https://video.ibm.com/embed/recorded/133967505"
                        title="CBAM Academy - Carbon Price"
                        width="100%"
                        height="auto"
                        key={'0'}
                      />
                    </div>
                  </p>
                </AccordionItem>
                <AccordionItem title={t('survey.step8.faq.accordion3.title')}>
                  <p>{t('survey.step8.faq.accordion3.text')}</p>
                </AccordionItem>
              </Accordion>
            </Column>
          </Grid>
        </div>
      </div>
      <Grid>
        <Column lg={16} md={8} sm={4}>
          <div className="step-btn-set-container">
            <ButtonSet className="step-btn-set">
              <Button kind="secondary" tabIndex={0} onClick={prevStep}>
                {t('common.buttons.back')}
              </Button>
              <Button kind="primary" tabIndex={0} type="submit">
                {t('common.buttons.next')}
              </Button>
            </ButtonSet>
          </div>
        </Column>
      </Grid>
    </Form>
  );
};

export default FormStep8;
