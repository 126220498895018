import {
  Accordion,
  AccordionItem,
  Button,
  ButtonSet,
  Column,
  Form,
  Grid,
  RadioTile,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
  TileGroup,
} from '@carbon/react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useChina } from '../../../hooks/useChina';
import useOnlyDevStage from '../../../hooks/useOnlyDevStage';
import { useTurkish } from '../../../hooks/useTurkish';
import VideoEmbed from '../../Vimeo/Vimeo';
import { FormStateAttribute } from '../FormMultisteps';
import '../FormMultisteps.scss';
import './FormStep3.scss';

const FormStep3 = ({ formState, prevStep, nextStep, handleChange }: any) => {
  const { t } = useTranslation();
  const isChina = useChina();
  const isTurkish = useTurkish();
  const isDev = useOnlyDevStage();

  var helpStringBuilder = formState.AGC;
  const newFrontendUrl = process.env.REACT_APP_NEW_FRONTEND_URL;

  const [showInvalidBorder, setShowInvalidBorder] = useState(false);

  const handleNextStep = (event: any) => {
    if (!formState.calculationMethod) {
      event.preventDefault();
      setShowInvalidBorder(true);
      return;
    }

    setShowInvalidBorder(false);

    if (formState.calculationMethod === 'measured' && isDev) {
      const jsonData = JSON.stringify(formState);
      const base64Data = btoa(jsonData);
      const encodedData = encodeURIComponent(base64Data);

      window.location.href = `${newFrontendUrl}/survey/${formState.id}?payload=${encodedData}`;
      event.preventDefault();
      return;
    }

    nextStep(event);
  };

  const handleCalMethod = (event: any) => {
    const calc: FormStateAttribute = {
      name: 'calculationMethod',
      value: event,
    };
    handleChange(calc);
  };

  const emissionCalculationOptions = () => {
    return (
      <TileGroup name="emissionCalculationMethod" valueSelected={formState.calculationMethod}>
        <RadioTile
          id="calculated-method"
          name="calculationMethod"
          value="calculated"
          className="radiotile"
          onClick={() => handleCalMethod('calculated')}
          style={{
            border: showInvalidBorder ? '2px solid red' : '2px solid transparent',
            marginBottom: '1rem',
          }}
        >
          <h6>{t('survey.step3.radioTiles.radioTile1.title')}</h6>
          {t('survey.step3.radioTiles.radioTile1.description')}
        </RadioTile>

        {isDev && (
          <RadioTile
            id="measured-method"
            name="measuredMethod"
            value="measured"
            className="radiotile"
            onClick={() => handleCalMethod('measured')}
            style={{
              border: showInvalidBorder ? '2px solid red' : '2px solid transparent',
            }}
          >
            <h6>{t('survey.step3.radioTiles.radioTile2.title')}</h6>
            {t('survey.step3.radioTiles.radioTile2.description')}
          </RadioTile>
        )}
      </TileGroup>
    );
  };

  const showHelperTextAccordingToAGC = () => {
    switch (formState.AGC) {
      case 'Sintered Ore':
      case 'Pig iron':
      case 'Cement clinker':
      case 'FeMn Ferro-Manganese':
      case 'FeCr Ferro-Chromium':
      case 'FeNi Ferro Nickel':
      case 'Direct reduced iron':
      case 'Crude Steel':
      case 'Iron or steel products':
      case 'Cement':
      case 'Urea':
      case 'Aluminous cement':
      case 'Mixed fertilizers':
      case 'Ammonia':
      case 'Aluminium products':
      case 'Unwrought aluminium':
      case 'Electricity (export to EU)':
      case 'Nitric acid':
      case 'Calcined clays':
      case 'Hydrogen':
        return (
          <Accordion>
            <AccordionItem title={t('survey.step3.faq.accordion4.title')}>
              <p>{t('survey.step3.faq.accordion4.text')}</p>
            </AccordionItem>
            <AccordionItem title={t('survey.step3.faq.accordion5.title')}>
              <div>
                <VideoEmbed
                  src={
                    isChina
                      ? 'https://video.ibm.com/embed/recorded/133967502'
                      : isTurkish
                      ? 'https://player.vimeo.com/video/990237903'
                      : 'https://player.vimeo.com/video/990237903'
                  }
                  title="CBAM Academy - Reporting Periods"
                  width="100%"
                  height="300px"
                />
              </div>
            </AccordionItem>
            <AccordionItem title={t('survey.step3.faq.accordion3.title')}>
              <p>{t('survey.step3.faq.accordion3.text')}</p>
            </AccordionItem>
          </Accordion>
        );
    }
    return null;
  };

  return (
    <>
      <Form onSubmit={handleNextStep} id="step3" className="form-step">
        <div className="grid-container-element">
          <div className="grid-child-element">
            <Grid>
              <Column lg={16} md={8} sm={4}>
                <h4>{t('survey.step3.name')}</h4>
              </Column>
              <Column lg={16} md={8} sm={4}>
                <Table aria-label="survey table">
                  <TableHead>
                    <TableRow>
                      <TableHeader>{t('common.parameterTable.CNNumber')}</TableHeader>
                      <TableHeader>{t('common.parameterTable.CNName')}</TableHeader>
                      <TableHeader>{t('survey.step3.AGC.labelText')} </TableHeader>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell>{formState.CNNumber}</TableCell>
                      <TableCell>{formState.CNName}</TableCell>
                      <TableCell>{formState.AGC}</TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </Column>
              <Column lg={16} md={8} sm={4}>
                <h5 style={{ marginBottom: '1rem' }}>{t('survey.step3.radioTiles.title')}</h5>
                {emissionCalculationOptions()}
              </Column>
            </Grid>
          </div>
          <div className="grid-child-element">
            <Grid>
              <Column lg={16} md={8} sm={4}>
                <h5 className="faq">{t('survey.step2.faq.title')}</h5>
                {showHelperTextAccordingToAGC()}
              </Column>
            </Grid>
          </div>
        </div>
        <Grid>
          <Column lg={16} md={8} sm={4}>
            <div className="step-btn-set-container">
              <ButtonSet className="step-btn-set">
                <Button kind="secondary" tabIndex={0} onClick={prevStep}>
                  {t('common.buttons.back')}
                </Button>
                <Button kind="primary" tabIndex={0} type="submit">
                  {t('common.buttons.next')}
                </Button>
              </ButtonSet>
            </div>
          </Column>
        </Grid>
      </Form>
    </>
  );
};

export default FormStep3;
