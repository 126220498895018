import React from 'react';
import { useTranslation } from 'react-i18next';
import { 
  StructuredListWrapper, 
  StructuredListHead, 
  StructuredListBody, 
  StructuredListRow, 
  StructuredListCell,
  Tag
} from '@carbon/react';

interface PowerSource {
  type: string;
  energyType?: string;
  consumption: number;
  unit: string;
  emissions: number;
}

interface PowerComparison {
  power: string;
  examples: string[];
}

interface PowerConsumptionComparisonProps {
  formState: any;
}

const PowerConsumptionComparison: React.FC<PowerConsumptionComparisonProps> = ({ formState }) => {
  const { t } = useTranslation();
  const powerSources: PowerSource[] = JSON.parse(formState.indirectPowerSourcesJson || '[]');
  const totalPowerConsumption = powerSources.reduce(
    (sum: number, source: PowerSource) => sum + Number(source.consumption), 
    0
  );

  const getPowerCategory = (power: number) => {
    if (power >= 100) return 0;
    if (power >= 10) return 1;
    if (power >= 1) return 2;
    return 3;
  };

  const powerCategory = getPowerCategory(totalPowerConsumption);

  const powerComparisons: PowerComparison[] = [
    {
      power: '100 MW+',
      examples: [
        t('survey.submission.power.example100_1'),
        t('survey.submission.power.example100_2'),
        t('survey.submission.power.example100_3'),
      ],
    },
    {
      power: '10 MW',
      examples: [
        t('survey.submission.power.example10_1'),
        t('survey.submission.power.example10_2'),
        t('survey.submission.power.example10_3'),
      ],
    },
    {
      power: '1 MW',
      examples: [
        t('survey.submission.power.example1_1'),
        t('survey.submission.power.example1_2'),
        t('survey.submission.power.example1_3'),
      ],
    },
    {
      power: '0.1 MW (100 kW)',
      examples: [
        t('survey.submission.power.example0_1'),
        t('survey.submission.power.example0_2'),
        t('survey.submission.power.example0_3'),
      ],
    },
  ];

  return (
    <div className="details-section mt-4">
      <div className="comparison-header">
        <h4>{t('survey.step5.name')}</h4>
      </div>
      
      {/* Power Sources Summary */}
      {powerSources.length > 0 && (
        <div className="power-sources-summary mb-4">
          <StructuredListWrapper>
            <StructuredListHead>
              <StructuredListRow head>
                <StructuredListCell head>{t('survey.step5.emissions.source')}</StructuredListCell>
                <StructuredListCell head>{t('survey.step5.emissions.consumption')}</StructuredListCell>
                <StructuredListCell head>{t('survey.step5.emissions.emissions')}</StructuredListCell>
              </StructuredListRow>
            </StructuredListHead>
            <StructuredListBody>
              {powerSources.map((source: PowerSource, index: number) => (
                <StructuredListRow key={index}>
                  <StructuredListCell>
                    <Tag type={source.type === 'grid' ? 'red' : source.type === 'ppa' ? 'blue' : 'green'}>
                      {t(`survey.step5.powerSource.${source.type}`)}
                    </Tag>
                    {source.energyType && ` - ${t(`survey.step5.directLink.energyTypes.${source.energyType}`)}`}
                  </StructuredListCell>
                  <StructuredListCell>
                    {source.consumption} {source.unit}
                  </StructuredListCell>
                  <StructuredListCell>
                    {Number(source.emissions).toFixed(2)} t CO2
                  </StructuredListCell>
                </StructuredListRow>
              ))}
              <StructuredListRow>
                <StructuredListCell>
                  <strong>{t('survey.step5.emissions.total')}</strong>
                </StructuredListCell>
                <StructuredListCell>
                  <strong>
                    {powerSources.reduce((sum: number, source: PowerSource) => 
                      sum + Number(source.consumption), 0).toFixed(2)} MWh
                  </strong>
                </StructuredListCell>
                <StructuredListCell>
                  <strong>{totalPowerConsumption.toFixed(2)} t CO2</strong>
                </StructuredListCell>
              </StructuredListRow>
            </StructuredListBody>
          </StructuredListWrapper>
        </div>
      )}

      {/* Power Level Context */}
      <div className="details-content">
        <div className="power-comparison-item highlight">
          <div className="power-level">
            <span className="label">{t('survey.step5.emissions.totalConsumption')} </span>
            <span className="value">
              {powerSources.reduce((sum: number, source: PowerSource) => 
                sum + Number(source.consumption), 0).toFixed(2)} MWh
            </span>
          </div>
        </div>

        {powerComparisons.map((comparison, index) => (
          <div
            key={comparison.power}
            className={`power-comparison-item ${index === powerCategory ? 'current' : ''}`}
          >
            <div className="power-level">
              <span className="label">{t('survey.step5.emissions.referenceLevel')} </span>
              <span className="value">{comparison.power}</span>
            </div>
            <div className="examples">
              <span className="label">{t('survey.step5.emissions.comparableTo')} </span>
              <ul className="example-list">
                {comparison.examples.map((example, i) => (
                  <li key={i} className="value">{example}</li>
                ))}
              </ul>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default PowerConsumptionComparison;