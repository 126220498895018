import React from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, Column } from '@carbon/react';
import SummarySection from '../SummarySection/SummarySection';

interface InstallationDetailsProps {
  formState: any;
}

const InstallationDetails: React.FC<InstallationDetailsProps> = ({ formState }) => {
  const { t } = useTranslation();

  return (
    <Grid>
      <Column lg={16} md={8} sm={4}>
        {/* Main Installation Info */}
        <div className="details-section installation-header">
          <h3 className="installation-name">{formState.installationName}</h3>
          <div className="economic-activity">
            <span className="label">{t('survey.step1.economicActivity.labelText')}</span>
            <span className="value">{formState.economicActivity}</span>
          </div>
        </div>
        
        {/* Production Data (if calculated method) */}
        {formState.calculationMethod === 'calculated' && (
          <div className="details-section production-section">
            <div className="details-header">
              <h4>{t('survey.submission.installationDetails.productionData')}</h4>
            </div>
            <div className="details-content">
              <div className="production-grid">
                <div className="details-item">
                  <span className="label">
                    {t('survey.step9.activityLevel.totalProduction')}
                  </span>
                  <span className="value highlight">{formState.productionTotal} t</span>
                </div>
                <div className="details-item">
                  <span className="label">
                    {t('survey.step9.activityLevel.scrapProduction')}
                  </span>
                  <span className="value highlight">{formState.productionScrap} t</span>
                </div>
                <div className="details-item">
                  <span className="label">
                    {t('survey.step9.activityLevel.totalProductionCalculated')}
                  </span>
                  <span className="value highlight">{formState.productionCalc} t</span>
                </div>
              </div>
            </div>
          </div>
        )}

        {/* Two Column Layout for Address and Contact */}
        <div className="details-grid">
          {/* Installation Address */}
          <SummarySection
            title={t('survey.step9.installationData.installationDataTitle')}
            items={[
              {
                label: t('survey.step9.installationData.address'),
                value: `${formState.street} ${formState.streetNumber}`
              },
              ...(formState.POBox ? [{
                label: t('survey.step1.POBox.labelText'),
                value: formState.POBox
              }] : []),
              ...(formState.additionalLine ? [{
                label: t('survey.step1.additionalLine.labelText'),
                value: formState.additionalLine
              }] : []),
              {
                label: t('survey.step1.city.labelText'),
                value: `${formState.postCode} ${formState.city}`
              },
              {
                label: t('survey.step1.country.labelText'),
                value: `${formState.country.country_name} - ${formState.country.country_code}`
              }
            ]}
            columns={1}
          />


          {/* Contact Person */}
          <SummarySection
            title={t('survey.step1.representative.labelText')}
            items={[
              {
                label: t('survey.step1.representative.labelText'),
                value: formState.representative || 'Not provided'
              },
              {
                label: t('survey.step1.email.labelText'),
                value: formState.email || 'Not provided'
              },
              {
                label: t('survey.step1.telephone.labelText'),
                value: formState.telephone || 'Not provided'
              }
            ]}
            columns={1}
          />
        </div>
      </Column>
    </Grid>
  );
};

export default InstallationDetails;