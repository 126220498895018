import React from 'react';
import { useTranslation } from 'react-i18next';
import { InlineNotification, Accordion, AccordionItem } from '@carbon/react';
import { CheckmarkFilled, WarningFilled, InformationFilled } from '@carbon/react/icons';
import ValidationHints from '../ValidationHints/ValidationHints';

interface ValidationSummaryProps {
  comparisonStatus: any;
  hasRenewableEnergy?: boolean;
  renewablePercentage?: number;
  purchasedEmissions?: any[];
  surveyStatus?: string;
  showDetailsAccordion?: boolean;
}

const ValidationSummary: React.FC<ValidationSummaryProps> = ({
  comparisonStatus,
  hasRenewableEnergy = false,
  renewablePercentage = 0,
  purchasedEmissions = [],
  surveyStatus,
  showDetailsAccordion = true,
}) => {
  const { t } = useTranslation();

  // Function to generate a summary of validation issues
  const generateSummary = (comparisonStatus: any) => {
    const warnings = [];

    // Direct emissions warnings
    if (comparisonStatus.direct?.status === 'warning') {
      const diff = comparisonStatus.direct.difference;
      if (diff > 20) {
        warnings.push(
          t('survey.submission.validation.summary.directHighEmission', {
            percentage: diff.toFixed(1),
          }),
        );
      } else if (diff < -20) {
        warnings.push(
          t('survey.submission.validation.summary.directLowEmission', {
            percentage: Math.abs(diff).toFixed(1),
          }),
        );
      }
    }

    // Indirect emissions warnings
    if (comparisonStatus.indirect?.status === 'warning') {
      const diff = comparisonStatus.indirect.difference;
      if (diff > 20) {
        warnings.push(
          t('survey.submission.validation.summary.indirectHighEmission', {
            percentage: diff.toFixed(1),
          }),
        );
      } else if (diff < -20) {
        warnings.push(
          t('survey.submission.validation.summary.indirectLowEmission', {
            percentage: Math.abs(diff).toFixed(1),
          }),
        );
      }
    }

    // Activity vs production warnings
    if (comparisonStatus.activity?.status === 'warning') {
      const diff = comparisonStatus.activity.difference;
      if (diff > 50) {
        warnings.push(
          t('survey.submission.validation.summary.activityHighDeviation', {
            percentage: diff.toFixed(1),
          }),
        );
      } else if (diff < -20) {
        warnings.push(
          t('survey.submission.validation.summary.activityLowDeviation', {
            percentage: Math.abs(diff).toFixed(1),
          }),
        );
      }
    }

    // Direct intensity warnings
    if (comparisonStatus.directIntensity?.status === 'warning') {
      const diff = comparisonStatus.directIntensity.difference;
      if (diff > 20) {
        warnings.push(
          t('survey.submission.validation.summary.directIntensityHigh', {
            percentage: diff.toFixed(1),
          }),
        );
      } else if (diff < -20) {
        warnings.push(
          t('survey.submission.validation.summary.directIntensityLow', {
            percentage: Math.abs(diff).toFixed(1),
          }),
        );
      }
    }

    // Indirect intensity warnings
    if (comparisonStatus.indirectIntensity?.status === 'warning') {
      const diff = comparisonStatus.indirectIntensity.difference;
      if (diff > 20) {
        warnings.push(
          t('survey.submission.validation.summary.indirectIntensityHigh', {
            percentage: diff.toFixed(1),
          }),
        );
      } else if (diff < -20) {
        warnings.push(
          t('survey.submission.validation.summary.indirectIntensityLow', {
            percentage: Math.abs(diff).toFixed(1),
          }),
        );
      }
    }

    return warnings;
  };

  // Function to generate fix proposals
  const generateFixProposals = (
    comparisonStatus: {
      direct: { status: string; difference: any };
      indirect: { status: string; difference: any };
      activity: { status: string; difference: any };
    },
    hasRenewableEnergy: boolean,
  ) => {
    const fixes = new Set();

    // Direct emissions fixes
    if (comparisonStatus.direct?.status === 'warning') {
      const diff = comparisonStatus.direct.difference;
      if (diff > 20) {
        fixes.add(t('survey.submission.validation.highEmission2'));
        fixes.add(t('survey.submission.validation.highEmission3'));
      } else if (diff < -20) {
        fixes.add(t('survey.submission.validation.lowEmission1'));
        fixes.add(t('survey.submission.validation.lowEmission3'));
      }
    }

    // Indirect emissions fixes
    if (comparisonStatus.indirect?.status === 'warning') {
      const diff = comparisonStatus.indirect.difference;
      if (diff > 20) {
        fixes.add(t('survey.submission.validation.highEmission2'));
      } else if (diff < -20) {
        if (hasRenewableEnergy) {
          // Special case for renewable energy
          fixes.add(t('survey.submission.validation.renewableLowEmission2'));
        } else {
          fixes.add(t('survey.submission.validation.lowEmission2'));
        }
      }
    }

    // Activity vs production fixes
    if (comparisonStatus.activity?.status === 'warning') {
      const diff = comparisonStatus.activity.difference;
      if (diff > 50) {
        fixes.add(t('survey.submission.validation.activityLevel1'));
        fixes.add(t('survey.submission.validation.activityLevel2'));
      } else if (diff < -20) {
        fixes.add(t('survey.submission.validation.activityLevel5'));
        fixes.add(t('survey.submission.validation.activityLevel6'));
      }
    }

    // Return as array
    return Array.from(fixes);
  };

  // Check if we have any validation warnings
  const hasWarnings =
    comparisonStatus.direct?.status === 'warning' ||
    comparisonStatus.indirect?.status === 'warning' ||
    comparisonStatus.activity?.status === 'warning' ||
    comparisonStatus.directIntensity?.status === 'warning' ||
    comparisonStatus.indirectIntensity?.status === 'warning';

  // Check if waiting for sub-surveys
  const isWaitingForSubSurveys =
    surveyStatus === 'waiting for sub-surveys' ||
    (purchasedEmissions && purchasedEmissions.length > 0);

  // If no warnings and not waiting for sub-surveys, show success notification
  if (!hasWarnings && !isWaitingForSubSurveys) {
    return (
      <InlineNotification
        kind="success"
        title={
          <span className="flex items-center">
            <CheckmarkFilled className="mr-2" />
            {t('survey.submission.validation.validationPassed')}
          </span>
        }
        subtitle={t('survey.submission.validation.allChecksPass')}
        hideCloseButton
      />
    );
  }

  // If waiting for sub-surveys, show info notification
  if (isWaitingForSubSurveys) {
    return (
      <InlineNotification
        kind="info"
        title={
          <span className="flex items-center">
            {t('survey.submission.validation.waitingForSubSurveys')}
          </span>
        }
        subtitle={
          <div>
            <p>{t('survey.submission.validation.subSurveyStatusExplanation')}</p>
            {purchasedEmissions && purchasedEmissions.length > 0 && (
              <div className="mt-3">
                <strong>{t('survey.submission.validation.purchasedMaterials')}:</strong>
                <ul className="list-disc pl-5 mt-1">
                  {purchasedEmissions.map((emission, index) => (
                    <li key={index}>
                      {emission.emission_name}
                      {emission.cn_code ? ` (${emission.cn_code})` : ''}
                    </li>
                  ))}
                </ul>
              </div>
            )}
            <p className="mt-3">
              {t('survey.submission.validation.surveyFinalizedAfterSubSurveys')}
            </p>
          </div>
        }
        hideCloseButton
      />
    );
  }

  // Otherwise, show warning notification with summary
  const warnings = generateSummary(comparisonStatus);
  const fixes = generateFixProposals(comparisonStatus, hasRenewableEnergy);

  return (
    <div className="validation-summary">
      <InlineNotification
        kind="warning"
        title={
          <span className="flex items-center">
            <WarningFilled className="mr-2" />
            {t('survey.submission.validation.thresholdViolations')}
          </span>
        }
        subtitle={
          <div>
            <p>{t('survey.submission.validation.reviewNeeded')}</p>

            <div className="mt-3">
              <strong>{t('survey.submission.validation.issues')}:</strong>
              <ul className="list-disc pl-5 mt-1">
                {warnings.map((warning, index) => (
                  <li key={index}>{warning}</li>
                ))}
              </ul>
            </div>

            {fixes.length > 0 && (
              <div className="mt-3">
                <strong>{t('survey.submission.validation.recommendations')}:</strong>
                <ul className="list-disc pl-5 mt-1">
                  {fixes.map((fix, index) => (
                    <li key={index}>{fix as string}</li>
                  ))}
                </ul>
              </div>
            )}

            <p className="mt-3">{t('survey.submission.validation.commentNeeded')}</p>
          </div>
        }
        hideCloseButton
      />

      {/* Detailed validation hints in accordion */}
      {showDetailsAccordion && (
        <Accordion className="mt-4">
          <AccordionItem
            title={t('survey.submission.validation.detailedValidationTitle')}
            className="validation-accordion"
          >
            <ValidationHints
              comparisonStatus={comparisonStatus}
              hasRenewableEnergy={hasRenewableEnergy}
              renewablePercentage={renewablePercentage}
            />
          </AccordionItem>
        </Accordion>
      )}
    </div>
  );
};

export default ValidationSummary;
