import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button,
  ButtonSet,
  Column,
  Form,
  Grid,
  InlineNotification,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  InlineLoading,
} from '@carbon/react';

import {
  DocumentUpload,
  PowerConsumptionComparison,
  InstallationDetails,
  EmissionCategoryList,
  EmissionsComparison,
  SummarySection,
} from '../../Features/SurveySummary';

import './FormStep9.scss';



export interface UploadFile {
  id: number;
  category: string;
  uploadDate: string;
  file: File;
}

interface IEmission {
  category: string;
  emission_name: string;
  activity: number | string;
  unit: 't' | 'kg';
  emission_id: number;
  direct_multiplier: number;
  indirect_multiplier: number;
}

interface PowerSource {
  type: 'grid' | 'ppa' | 'directLink';
  consumption: number;
  emissions: number;
  unit: string;
  energyType?: string;
  subType?: string;
}

const FormStep9 = ({ formState, prevStep, handleSubmit: submitForm, onChangeFiles }: any) => {
  const { t } = useTranslation();
  const backendUrl = process.env.REACT_APP_BACKEND_URL;

  const [defaultEmissions, setDefaultEmissions] = useState<any>(null);
  const [emissionIntensity, setEmissionIntensity] = useState<any>(null);
  const [comparisonStatus, setComparisonStatus] = useState({
    direct: { status: 'loading', difference: 0 },
    indirect: { status: 'loading', difference: 0 },
    activity: { status: 'loading', difference: 0 },
    directIntensity: { status: 'loading', difference: 0 },
    indirectIntensity: { status: 'loading', difference: 0 },
  });
  const [uploadFiles, setUploadFiles] = useState<UploadFile[]>([]);
  const [hasRenewableEnergy, setHasRenewableEnergy] = useState<boolean>(false);
  const [renewablePercentage, setRenewablePercentage] = useState<number>(0);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const handleAddFile = (category: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;

    if (!files) return;
    const fileArray = Array.from(files);

    if (fileArray) {
      const newFiles = fileArray.map((file, index) => ({
        id: uploadFiles.length + index + 1,
        category,
        uploadDate: new Date().toISOString(),
        file,
      }));

      setUploadFiles([...uploadFiles, ...newFiles]);
      onChangeFiles([...uploadFiles, ...newFiles]);
    }
  };

  const handleRemoveFile = (fileId: number) => {
    const newFiles = uploadFiles.filter((file) => file.id !== fileId);
    setUploadFiles(newFiles);
    onChangeFiles(newFiles);
  };

  const convertToTonnes = (value: number, unit: 't' | 'kg'): number => {
    return unit === 'kg' ? value / 1000 : value;
  };

  const calculateTotalEmissions = (emissions: IEmission[]): number => {
    return emissions
      .filter(emission => emission.category !== "CBAM_PRFG")
      .reduce((sum: number, emission: IEmission) => {
        const activityValue = Number(emission.activity);
        if (isNaN(activityValue)) {
          console.warn(`Invalid activity value: ${emission.activity}`);
          return sum;
        }
        return sum + convertToTonnes(activityValue, emission.unit);
      }, 0);
  };


  useEffect(() => {
    if (formState.indirectPowerSourcesJson) {
      try {
        const powerSources: PowerSource[] = JSON.parse(formState.indirectPowerSourcesJson);
        
        // Calculate total consumption
        const totalConsumption = powerSources.reduce((sum, source) => sum + source.consumption, 0);
        if (totalConsumption === 0) {
          setHasRenewableEnergy(false);
          setRenewablePercentage(0);
          return;
        }
        
        // Check for renewable sources and calculate percentage
        let renewableConsumption = 0;
        let hasRenewable = false;
        
        powerSources.forEach(source => {
          // Direct technical link with renewable energy type
          if (source.type === 'directLink' && source.energyType === 'renewable') {
            hasRenewable = true;
            renewableConsumption += source.consumption;
          }
          
          // PPA with low emissions factor (typically indicates renewable)
          // Assuming emission factor below 0.1 t CO2/MWh is likely renewable
          if (source.type === 'ppa' && (source.emissions / source.consumption) < 0.1) {
            hasRenewable = true;
            renewableConsumption += source.consumption;
          }
        });
        
        setHasRenewableEnergy(hasRenewable);
        setRenewablePercentage((renewableConsumption / totalConsumption) * 100);
      } catch (error) {
        console.error('Error parsing power sources:', error);
        setHasRenewableEnergy(false);
        setRenewablePercentage(0);
      }
    }
  }, [formState.indirectPowerSourcesJson]);

  // Adjust threshold based on renewable energy percentage
  const getIndirectEmissionThreshold = () => {
    // Base threshold is 20%
    let threshold = 20;
    
    // If renewable percentage is significant, increase the threshold
    if (renewablePercentage >= 75) {
      threshold = 80; // Very high threshold for mostly renewable
    } else if (renewablePercentage >= 50) {
      threshold = 60; // High threshold for majority renewable
    } else if (renewablePercentage >= 25) {
      threshold = 40; // Medium threshold for partial renewable
    } else if (renewablePercentage > 0) {
      threshold = 30; // Slightly increased threshold for some renewable
    }
    
    return threshold;
  };

  useEffect(() => {
    const fetchAndCompareEmissions = async () => {
      try {
        const response = await fetch(`${backendUrl}/api/emissions/default/${formState.CNNumber}`);
        const defaultData = await response.json();
        setDefaultEmissions(defaultData);
  
        // Initialize intensityData as null
        let intensityData = null;
        
        // Try to fetch intensity data if country code is available
        if (formState.country?.country_code) {
          try {
            const cnNumberString = String(formState.CNNumber);
            const intensityResponse = await fetch(
              `${backendUrl}/api/emissions/intensity/${cnNumberString}/${formState.country.country_code}`,
            );
  
            if (intensityResponse.ok) {
              intensityData = await intensityResponse.json();
              if (intensityData) {
                setEmissionIntensity(intensityData);
              }
            } else {
              console.log('No intensity data available for this country');
            }
          } catch (error) {
            console.error('Error fetching intensity data:', error);
          }
        }
        
        if (defaultData) {
          const totalActivity = calculateTotalEmissions(formState.directEmissions);
  
          // Direct emissions difference calculation
          const directDiff =
            ((formState.calculatedEmissionsDirectPerTonne - defaultData.direct) /
            defaultData.direct) * 100;
          
          // Indirect emissions difference calculation
          const indirectDiff = 
            ((formState.calculatedEmissionsIndirectPerTonne - defaultData.indirect) / 
            defaultData.indirect) * 100;
          
          // Activity vs production difference calculation
          const activityDiff =
            ((totalActivity - formState.productionCalc) / formState.productionCalc) * 100;
  
          // Get threshold adjusted for renewable energy
          const indirectThreshold = hasRenewableEnergy ? getIndirectEmissionThreshold() : 20;
          const directDiffValue = isNaN(directDiff) ? 0 : directDiff;
          const indirectDiffValue = isNaN(indirectDiff) ? 0 : indirectDiff;
          const activityDiffValue = isNaN(activityDiff) ? 0 : activityDiff;
          
          // Determine status based on threshold and difference
          // For indirect emissions
          let indirectStatus;
          if (indirectDiffValue < 0) {
            // For negative differences (lower than reference), check against adjusted threshold
            indirectStatus = Math.abs(indirectDiffValue) > indirectThreshold ? 'warning' : 'success';
          } else {
            // For positive differences (higher than reference), use standard threshold
            indirectStatus = indirectDiffValue > 20 ? 'warning' : 'success';
          }
  
          // Create a new comparison status object
          const newComparisonStatus = {
            direct: {
              status: Math.abs(directDiffValue) > 20 ? 'warning' : 'success',
              difference: directDiffValue,
            },
            indirect: {
              status: indirectStatus,
              difference: indirectDiffValue, 
            },
            activity: {
              status: activityDiffValue < -20 || activityDiffValue > 50 ? 'warning' : 'success',
              difference: activityDiffValue,
            },
            // Default values for intensity metrics if no country data is available
            directIntensity: {
              status: 'success',
              difference: 0,
            },
            indirectIntensity: {
              status: 'success',
              difference: 0,
            },
          };
  
          // Only override the intensity values if we have actual intensity data
          if (intensityData) {
            const directIntensityDiff =
              ((formState.calculatedEmissionsDirectPerTonne - intensityData.direct_emissions) /
                intensityData.direct_emissions) *
              100;
            const indirectIntensityDiff =
              ((formState.calculatedEmissionsIndirectPerTonne - intensityData.indirect_emissions) /
                intensityData.indirect_emissions) *
              100;
  
            // For indirect intensity
            let indirectIntensityStatus;
            if (indirectIntensityDiff < 0) {
              // For negative differences (lower than reference), check against adjusted threshold
              indirectIntensityStatus = Math.abs(indirectIntensityDiff) > indirectThreshold ? 'warning' : 'success';
            } else {
              // For positive differences (higher than reference), use standard threshold
              indirectIntensityStatus = indirectIntensityDiff > 20 ? 'warning' : 'success';
            }
  
            newComparisonStatus.directIntensity = {
              status: Math.abs(directIntensityDiff) > 20 ? 'warning' : 'success',
              difference: directIntensityDiff,
            };
            
            newComparisonStatus.indirectIntensity = {
              status: indirectIntensityStatus,
              difference: indirectIntensityDiff,
            };
          }
  
          // Update the comparison status with the new object
          setComparisonStatus(newComparisonStatus);
        }
      } catch (err) {
        console.error('Error fetching emissions data:', err);
        
        // Even on error, update comparison status with default values
        // This ensures we don't stay in loading state
        setComparisonStatus({
          direct: { status: 'success', difference: 0 },
          indirect: { status: 'success', difference: 0 },
          activity: { status: 'success', difference: 0 },
          directIntensity: { status: 'success', difference: 0 },
          indirectIntensity: { status: 'success', difference: 0 },
        });
      }
    };
    if (formState.CNNumber) {
      fetchAndCompareEmissions();
    }
  }, [
    formState.CNNumber,
    formState.calculatedEmissionsDirectPerTonne,
    formState.calculatedEmissionsIndirectPerTonne,
    formState.country?.country_code,
    formState.directEmissions,
    formState.productionCalc,
    hasRenewableEnergy,
    renewablePercentage,
    backendUrl,
  ]);

  const handleFormSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();   
    const isLoading = 
    comparisonStatus.direct.status === 'loading' ||
    comparisonStatus.indirect.status === 'loading' ||
    comparisonStatus.activity.status === 'loading';
    
  if (isLoading) {
    // Show notification to user that validation is still in progress
    alert(t('survey.submission.validation.stillLoading'));
    return;
  }
  
  try {
    // Call the parent submit handler with the comparison status
    await submitForm(event, comparisonStatus);
  } catch (error) {
    console.error('Error during form submission:', error);
  }
};

  // Calculate the correct delta percentage for activity vs production
  const totalActivity = calculateTotalEmissions(formState.directEmissions);
  const productionCalc = formState?.productionCalc ?? 0;
  const activityDelta = productionCalc > 0 
    ? ((totalActivity - productionCalc) / productionCalc) * 100 
    : 0;

  return (
    <Form onSubmit={handleFormSubmit} id="step9" className="form-step">
      <h3 className="form-step-title">{t('survey.step9.name')}</h3>

      <InlineNotification
        kind="info"
        hideCloseButton
        title={t('survey.step9.infoTitle')}
        subtitle={t('survey.step9.infoSubtitle')}
      />
      {!emissionIntensity && comparisonStatus.directIntensity.status !== 'loading' && (
        
      <InlineNotification
        kind="info"
        hideCloseButton
        title={t('survey.step9.noCountryData')}
        subtitle={t('survey.step9.onlyDefaultComparison')}
      />
    )}

      {/* Display sub-survey warning message if applicable */}
      {formState.purchasedEmissions && formState.purchasedEmissions.length > 0 && (
        <InlineNotification
          kind="warning"
          hideCloseButton
          title={t('survey.step9.subSurveyWarningTitle')}
          subtitle={t('survey.step9.subSurveyWarningDesc')}
        />
      )}
      <Tabs>
        <TabList aria-label="Survey Summary">
          <Tab>{t('survey.submission.navigation.overviewTab')}</Tab>
          <Tab>{t('survey.submission.navigation.emissionsTab')}</Tab>
          <Tab>{t('survey.submission.navigation.installationTab')}</Tab>
          <Tab>{t('survey.submission.navigation.additionalTab')}</Tab>
        </TabList>

        <TabPanels>
          {/* Overview Tab */}
          <TabPanel>
            <div className="summary-header">
              <Grid>
                <Column sm={4} md={8} lg={16}>
                  <SummarySection
                    items={[
                      {
                        label: t('common.parameterTable.CNNumber'),
                        value: formState.CNNumber
                      },
                      {
                        label: t('common.parameterTable.CNName'),
                        value: formState.CNName
                      },
                      {
                        label: t('survey.step3.name'),
                        value: formState.AGC
                      },
                      {
                        label: t('survey.step9.reportingPeriod.reportingPeriodTitle'),
                        value: `${formState.start} - ${formState.end}`
                      }
                    ]}
                    highlight={true}
                  />
                </Column>
              </Grid>
            </div>
            <h3>{t('survey.submission.prevalidation.prevalidationTitle')}</h3>
            <EmissionsComparison 
              formState={formState}
              comparisonStatus={comparisonStatus}
              defaultEmissions={defaultEmissions}
              emissionIntensity={emissionIntensity}
              hasRenewableEnergy={hasRenewableEnergy}
              renewablePercentage={renewablePercentage}
            />
          </TabPanel>

          {/* Emissions Tab */}
          <TabPanel>
            <Grid>
              <Column sm={4} md={8} lg={16}>
                {/* Direct Emissions Section */}
                <div className="details-section">
                  <div className="comparison-header">
                    <h4>{t('survey.submission.prevalidation.directEmissionDetails')}</h4>
                  </div>
                  <div className="details-content">
                    <EmissionCategoryList emissions={formState.directEmissions} />
                  </div>
                </div>

                {/* Indirect Emissions Section */}
                <PowerConsumptionComparison formState={formState} />
              </Column>
            </Grid>
          </TabPanel>

          {/* Installation Tab */}
          <TabPanel>
            <InstallationDetails formState={formState} />
          </TabPanel>

          {/* Documents Tab */}
          <TabPanel>
            <Grid>
              <Column lg={16} md={8} sm={4}>
                <DocumentUpload 
                  uploadFiles={uploadFiles}
                  onAddFile={handleAddFile}
                  onRemoveFile={handleRemoveFile}
                />
              </Column>
            </Grid>
          </TabPanel>
        </TabPanels>
      </Tabs>

      {/* Form buttons */}
      <Grid>
  <Column lg={16} md={8} sm={4}>
    <div className="step-btn-set-container">
      
      <ButtonSet className="step-btn-set">
              <Button 
                kind="secondary" 
                onClick={prevStep}
                disabled={isSubmitting}
              >
                {t('common.buttons.back')}
              </Button>
              
              {isSubmitting ? (
                <InlineLoading 
                  description={
                    formState.purchasedEmissions && formState.purchasedEmissions.length > 0
                      ? t('common.buttons.preparingSubSurveys')
                      : t('common.buttons.submitting')
                  }
                  status="active"
                  className="submit-loading"
                />
              ) : (
                <Button 
                  kind="primary" 
                  type="submit"
                  disabled={isSubmitting}
                >
                  {formState.purchasedEmissions && formState.purchasedEmissions.length > 0
                    ? t('common.buttons.submitWithSubSurveys')
                    : t('common.buttons.submit')
                  }
                </Button>
              )}
            </ButtonSet>
    </div>
  </Column>
</Grid>
    </Form>
  );
};

export default FormStep9;