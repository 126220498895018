import { Column, Grid } from '@carbon/react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import FormMultisteps from '../../components/FormMultisteps/FormMultisteps';
import { useSurvey } from '../../contexts/SurveyContext';
import { SurveySupplierInput } from '../../types/SurveySupplierInput';
import { SurveyViewData } from '../../types/SurveyViewData';
import './SurveyPage.scss';

const SurveyPage = () => {
  const [currentStep, setCurrentStep] = useState(1);
  const { survey } = useSurvey();
  const [surveyData, setSurveyData] = useState<SurveySupplierInput>({
    id: '',
    AGC: '',
    CNName: '',
    CNNumber: '',
    dueDate: '',
    importerID: '',
    importerName: '',
    status: '',
    supplierID: '',
    supplierName: '',

    // step 1 variables:
    installationName: '',
    streetNumber: '',
    street: '',
    economicActivity: '',
    postCode: '',
    POBox: '',
    city: '',
    country: undefined,
    representative: '',
    email: '',
    telephone: '',
    additionalLine: '',

    // step 2 variables:
    start: '01/01/2024',
    end: '31/12/2024',

    // step 3 variables: none
    // no variables - showing AGC only
    calculationMethod: 'calculated',

    // step 4 variables
    // route_id: 0,
    // route_name: '',

    directEmissions: [],
    purchasedEmissions: [],

    // step 5 variables
    //indirectEmission: 0,

    // step 6 variables

    // step 8
    formOfCarbonPrice: 5,
    formOfRebate: 6,

    // productionTotal: 0,
    // productionScrap: 0,

    // survey status
    finalisedSurvey: false,
  });

  useEffect(() => {
    let survey_view = survey as SurveyViewData;
    setSurveyData((prevState) => ({
      ...prevState,
      id: survey_view.id,
      AGC: survey_view.aggregated_goods_category,
      CNName: survey_view.cn_name,
      CNNumber: survey_view.cn_number,
      dueDate: survey_view.due_date,
      importerID: survey_view.importer_id,
      importerName: survey_view.importer_name,
      status: survey_view.status,
      supplierID: survey_view.supplier_id,
      supplierName: survey_view.supplier_name,
    }));
  }, []);

  return (
    <Grid>
      <>
        <Column lg={16} md={8} sm={4}>
          {surveyData && (
            <FormMultisteps
              data={surveyData}
              currentStep={currentStep}
              setCurrentStep={setCurrentStep}
            />
          )}
        </Column>
      </>
    </Grid>
  );
};
export default SurveyPage;
