import React, { FormEvent } from 'react';
import { useEffect, useRef, useState } from 'react';
import {
  Accordion,
  AccordionItem,
  Button,
  ButtonSet,
  Column,
  Form,
  Grid,
  Tile,
  Dropdown
} from '@carbon/react';
import { useTranslation } from 'react-i18next';
import VideoEmbed from '../../Vimeo/Vimeo';
import EmissionsDisplay from '../../Features/PowerSources/EmissionsDisplay';
import PowerFromGrid from '../../Features/PowerSources/PowerFromGrid';
import PowerPurchaseAgreement from '../../Features/PowerSources/PowerPurchaseAgreement';
import DirectTechnicalLink from '../../Features/PowerSources/DirectTechnicalLink';
import { SurveySupplierInput, IndirectPowerSource } from '../../../types/SurveySupplierInput';
import { FormStep5Props, PowerSource, PowerSourceOption } from '../../../types/IndirectEmissions';
import { useChina } from '../../../hooks/useChina';
import { useTurkish } from '../../../hooks/useTurkish';

const InstallationDetails: React.FC<{ formState: SurveySupplierInput }> = ({ formState }) => {
  const { t } = useTranslation();
  return (
    <Tile className="installation-details mb-4">
      <h5 className="text-lg font-medium mb-2">{t('survey.step5.installation.title')}</h5>
      {formState.installationName && (
        <p className="mb-1">
          <strong>{formState.installationName}</strong>
        </p>
      )}
      <p className="mb-1">
        {formState.streetNumber && `${formState.streetNumber}, `}
        {formState.city}
      </p>
      {formState.country && (
        <p className="mb-1">
          {formState.country.country_name} - {formState.country.country_code}
        </p>
      )}
      {formState.route && (
        <p className="mb-1">
          <strong>{t('survey.step5.installation.route')}:</strong> {formState.route.name}
        </p>
      )}
    </Tile>
  );
};

const FormStep5: React.FC<FormStep5Props> = ({ formState, handleChange, prevStep, nextStep }) => {
  const { t } = useTranslation();
  const [powerSources, setPowerSources] = useState<PowerSource[]>([]);
  const [currentSource, setCurrentSource] = useState<string>('grid');
  const [videoKey] = useState<number>(0);
  const isChina = useChina();
  const isTurkish = useTurkish();

  useEffect(() => {
    if (formState.indirectPowerSourcesJson) {
      try {
        const savedSources = JSON.parse(formState.indirectPowerSourcesJson);
        setPowerSources(savedSources);
      } catch (e) {
        console.error('Error parsing power sources:', e);
      }
    }
  }, [formState.indirectPowerSourcesJson]);
  
  const powerSourceOptions: PowerSourceOption[] = [
    { id: 'grid', text: t('survey.step5.grid.title') },
    { id: 'ppa', text: t('survey.step5.powerSource.ppa') },
    { id: 'directLink', text: t('survey.step5.powerSource.directLink') }
  ];

  const handlePowerSourceChange = ({ selectedItem }: { selectedItem: PowerSourceOption | null }): void => {
    if (selectedItem) {
      setCurrentSource(selectedItem.id);
    }
  };

  const savePowerSources = (sources: IndirectPowerSource[]) => {
    handleChange({
      target: {
        name: 'indirectPowerSourcesJson',
        value: JSON.stringify(sources)
      }
    });

    const total = sources.reduce((sum, source) => sum + (Number(source.emissions) || 0), 0);
    handleChange({
      target: {
        name: 'indirectEmission',
        value: total
      }
    });
  };

  const addEmissionSource = (sourceData: IndirectPowerSource): void => {
    const updatedSources = [...powerSources, sourceData];
    setPowerSources(updatedSources);
    savePowerSources(updatedSources);
  };

  const removeEmissionSource = (indexToRemove: number): void => {
    const updatedSources = powerSources.filter((_, index) => index !== indexToRemove);
    setPowerSources(updatedSources);
    savePowerSources(updatedSources);
  };

  const updateTotalEmissions = (sources: PowerSource[]): void => {
    const total = sources.reduce((sum, source) => sum + (Number(source.emissions) || 0), 0);
    handleChange({
      target: {
        name: 'indirectEmission',
        value: total
      }
    });
  };

  const handleGridEmission = (consumption: number, emission: number): void => {
    const sourceData: PowerSource = {
      type: 'grid',
      consumption,
      emissions: emission,
      unit: 'MWh'
    };
    addEmissionSource(sourceData);
  };

  const handlePPAEmission = (consumption: number, emission: number): void => {
    const sourceData: PowerSource = {
      type: 'ppa',
      consumption,
      emissions: emission,
      unit: 'MWh'
    };
    addEmissionSource(sourceData);
  };

  const handleDirectLinkEmission = (sources: Array<Omit<PowerSource, 'unit'>>): void => {
    sources.forEach(source => {
      const sourceData: PowerSource = {
        ...source,
        type: 'directLink',
        unit: 'MWh'
      };
      addEmissionSource(sourceData);
    });
  };

  const handleSubmit = (event: FormEvent): void => {
    event.preventDefault();
    

    if (formState.calculationMethod !== 'default' && (!powerSources || powerSources.length === 0)) {
      alert(t('survey.step5.validation.requireSource')); 
      return;
    }
    
    nextStep(event);
  };
  return (
    <Form onSubmit={handleSubmit} id="step5" className="form-step">
      <div className="grid-container-element">
        <div className="grid-child-element">
          <Grid>
            <Column lg={16} md={8} sm={4}>
              <h4>
                {t('survey.step5.name')}
                {formState.CNNumber && ` - ${formState.CNNumber}`}
              </h4>
              <InstallationDetails formState={formState} />
            </Column>

            {formState.calculationMethod !== 'default' && (
              <>
                <Column lg={16} md={8} sm={4}>
                  <Dropdown
                    id="power-source-dropdown"
                    titleText={t('survey.step5.powerSource.selectLabel')}
                    items={powerSourceOptions}
                    itemToString={(item: PowerSourceOption | null) => (item ? item.text : '')}
                    selectedItem={powerSourceOptions.find(option => option.id === currentSource)}
                    onChange={handlePowerSourceChange}
                  />
                </Column>

                <Column lg={16} md={8} sm={4}>
                  {currentSource === 'grid' && (
                    <PowerFromGrid
                      handleChange={handleGridEmission}
                      formState={formState}
                    />
                  )}

                  {currentSource === 'ppa' && (
                    <PowerPurchaseAgreement
                      handleChange={handlePPAEmission}
                    />
                  )}

                  {currentSource === 'directLink' && (
                    <DirectTechnicalLink
                      onSourcesAdded={handleDirectLinkEmission}
                    />
                  )}
                </Column>

                {powerSources.length > 0 && (
                  <Column lg={16} md={8} sm={4}>
                    <Tile className="mt-4">
                      <h5>{t('survey.step5.emissions.summary')}</h5>
                      <EmissionsDisplay 
                          sources={powerSources} 
                          onRemoveSource={removeEmissionSource} 
                        />
                    </Tile>
                  </Column>
                )}
              </>
            )}
            
            {formState.calculationMethod === 'default' && (
              <Column lg={8} md={4} sm={4}>
                <p>
                  {t('survey.step5.default.message')}
                </p>
              </Column>
            )}
          </Grid>
        </div>
        
        <div className="grid-child-element">
          <Grid>
            <Column lg={16} md={8} sm={4}>
              <h5 className="faq">{t('survey.step5.faq.title')}</h5>
              <Accordion>
                <AccordionItem title={t('survey.step5.faq.accordion1.title')}>
                  <p>{t('survey.step5.faq.accordion1.text')}</p>
                </AccordionItem>
                <AccordionItem title={t('survey.step5.faq.accordion2.title')}>
                  <p>
                    {t('survey.step5.faq.accordion2.text')}
                    <div>
                    <VideoEmbed
                src={
                  isChina
                    ? 'https://video.ibm.com/embed/recorded/134260890'
                    : isTurkish
                    ? 'https://player.vimeo.com/video/1063357738'
                    : 'https://player.vimeo.com/video/990236147?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479'
                }



                
                title="CBAM Academy - Indirect Emissions"
                width="100%"
                        height="auto"
                        key={videoKey.toString()}
                      />
                    </div>
                  </p>
                </AccordionItem>
                <AccordionItem title={t('survey.step5.faq.accordion3.title')}>
                  <p>{t('survey.step5.faq.accordion3.text')}</p>
                </AccordionItem>
              </Accordion>
            </Column>
          </Grid>
        </div>
      </div>
      
      <Grid>
        <Column lg={16} md={8} sm={4}>
          <div className="step-btn-set-container">
            <ButtonSet className="step-btn-set">
              <Button kind="secondary" onClick={prevStep}>
                {t('common.buttons.back')}
              </Button>
              <Button kind="primary" type="submit">
                {t('common.buttons.next')}
              </Button>
            </ButtonSet>
          </div>
        </Column>
      </Grid>
    </Form>
  );
};


export default FormStep5;