import React, { ReactNode } from 'react';
import { Tag } from '@carbon/react';
import { CheckmarkFilled, WarningFilled } from '@carbon/react/icons';

interface ComparisonValue {
  label: string;
  value: string | number | ReactNode;
  status?: 'warning' | 'success';
  difference?: number;
  forceShowDifference?: boolean; // New prop to force showing difference
}

interface ComparisonSectionProps {
  title: string;
  values: ComparisonValue[];
  statusTags?: Array<{
    label: string;
    status: 'warning' | 'success';
  }>;
  columns?: 1 | 2 | 3;
  className?: string;
}

const ComparisonSection: React.FC<ComparisonSectionProps> = ({
  title,
  values,
  statusTags = [],
  columns = 3,
  className = '',
}) => {
  const getGridTemplateColumns = () => {
    switch (columns) {
      case 1:
        return '1fr';
      case 2:
        return 'repeat(2, 1fr)';
      case 3:
      default:
        return 'repeat(3, 1fr)';
    }
  };

  // Helper function to determine if a difference should be displayed
  const shouldDisplayDifference = (item: ComparisonValue): boolean => {
    // If forceShowDifference is true, always show the difference
    if (item.forceShowDifference) {
      return true;
    }
    
    // Special case for zero emissions (difference = -100%)
    if (item.difference !== undefined && Math.abs(item.difference - (-100)) < 0.1) {
      return true;
    }
    
    // Display if difference exists and is not exactly zero
    // Using a small epsilon for floating point comparison
    return item.difference !== undefined && Math.abs(item.difference) >= 0.001;
  };

  // Format the difference with appropriate sign and decimal places
  const formatDifference = (difference: number): string => {
    // Special formatting for exactly -100% (zero emissions case)
    if (Math.abs(difference - (-100)) < 0.1) {
      return "-100.0%";
    }
    
    const sign = difference > 0 ? '+' : '';
    return `${sign}${difference.toFixed(1)}%`;
  };

  return (
    <div className={`comparison-section ${className}`}>
      <div className="comparison-header">
        <h4>{title}</h4>
        {statusTags.length > 0 && (
          <div className="tag-container">
            {statusTags.map((tag, index) => (
              <Tag
                key={index}
                type={tag.status === 'warning' ? 'red' : 'green'}
                renderIcon={tag.status === 'warning' ? WarningFilled : CheckmarkFilled}
              >
                {tag.label}
              </Tag>
            ))}
          </div>
        )}
      </div>
      <div
        className="comparison-values"
        style={{ gridTemplateColumns: getGridTemplateColumns() }}
      >
        {values.map((item, index) => (
          <div key={index} className="value-item">
            <span className="label">{item.label}</span>
            <span className={`value ${item.status ? item.status : ''}`}>
              {item.value}
              {shouldDisplayDifference(item) && (
                <span className={item.status === 'warning' ? 'warning' : 'success'}>
                  {` (${formatDifference(item.difference!)})`}
                </span>
              )}
            </span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ComparisonSection;