export const sortDropdown = <T>(
  list: T[],
  sortFn: (a: T, b: T) => number,
  noneIndex: number = 0,
  otherIndex: number = list.length - 1,
): T[] => {
  const noneElement = list[noneIndex];
  const otherElement = list[otherIndex];
  const restList = list
    .filter((e) => e !== noneElement)
    .filter((e) => e !== otherElement)
    .sort(sortFn);
  return [noneElement, ...restList, otherElement];
};
