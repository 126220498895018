import { useLanguage } from './useLanguage';

export const useChina = () => {
  const language = useLanguage();

  return (
    language.language === 'cn' ||
    ['zh', 'zh-CN', 'zh-TW', 'zh-HK'].includes(language.browserLanguage)
  );
};
