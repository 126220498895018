import React from 'react';

interface VideoEmbedProps {
  src: string;
  title: string;
  width?: string;
  height?: string;
}

const VideoEmbed: React.FC<VideoEmbedProps> = ({ src, title, width = '100%', height = '315px' }) => {
  return (
    <iframe
      src={src}
      width={width}
      height={height}
      frameBorder="0"
      allow="autoplay; fullscreen; picture-in-picture"
      allowFullScreen
      title={title}
      style={{ maxWidth: '100%', marginTop: '10px', marginBottom: '10px' }}
    />
  );
};

export default VideoEmbed;