import React from 'react';
import { useTranslation } from 'react-i18next';

interface IEmission {
  category: string;
  emission_name: string;
  activity: number | string;
  unit: 't' | 'kg';
  emission_id: number;
  direct_multiplier: number;
  indirect_multiplier: number;
}

interface EmissionCategoryProps {
  categoryName: string;
  emissions: IEmission[];
}

const EmissionCategory: React.FC<EmissionCategoryProps> = ({ categoryName, emissions }) => {
  const { t } = useTranslation();

  if (emissions.length === 0) {
    return null;
  }

  return (
    <div className="emissions-category">
      <h5 className="category-title">{categoryName}</h5>
      {emissions.map((item) => (
        <div key={item.emission_id} className="emission-item">
          <div className="emission-name">
            <span className="label">
              {t('survey.submission.prevalidation.directEmissionSource')}
            </span>
            <span className="value"> {item.emission_name}</span>
          </div>
          <div className="emission-details">
            <div className="detail-item">
              <span className="label">
                {t('survey.submission.prevalidation.directActivityData')}
              </span>
              <span className="value">{`${item.activity} ${item.unit}`}</span>
            </div>
            <div className="detail-item">
              <span className="label">
                {t('survey.submission.prevalidation.directFactor')}
              </span>
              <span className="value">{item.direct_multiplier}</span>
            </div>
            <div className="detail-item">
              <span className="label">
                {t('survey.submission.prevalidation.indirectFactor')}
              </span>
              <span className="value">{item.indirect_multiplier}</span>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

// Collection component for multiple categories
interface EmissionCategoryListProps {
  emissions: IEmission[];
}

export const EmissionCategoryList: React.FC<EmissionCategoryListProps> = ({ emissions }) => {
  // Group emissions by category
  const categories: Record<string, { name: string; items: IEmission[] }> = {
    CBAM_PRFG: { name: 'Fuels & Gases', items: [] },
    CBAM_PRRP: { name: 'Precursors', items: [] },
    CBAM_PROI: { name: 'Other Inputs', items: [] },
  };

  emissions.forEach((emission) => {
    if (categories[emission.category]) {
      categories[emission.category].items.push(emission);
    }
  });

  return (
    <>
      {Object.entries(categories).map(([key, category]) => (
        <EmissionCategory
          key={key}
          categoryName={category.name}
          emissions={category.items}
        />
      ))}
    </>
  );
};

export default EmissionCategoryList;