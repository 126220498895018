import { useOidc } from '@axa-fr/react-oidc';
import { Button, Column, Grid, Loading, Modal, SkeletonText, Tile } from '@carbon/react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import greenFactory from '../../assets/img/factory.png';
import ParameterTable from '../../components/ParameterTable/ParameterTable';
import ParameterTableSkeleton from '../../components/ParameterTable/ParameterTableSkeleton';
import VideoEmbed from '../../components/Vimeo/Vimeo';
import {
  LOCAL_STORAGE_OIDC_CONFIG_NAME,
  LOCAL_STORAGE_RELOAD_FLAG,
} from '../../constants/constants';
import { useSurvey } from '../../contexts/SurveyContext';
import { useChina } from '../../hooks/useChina';
import { useTurkish } from '../../hooks/useTurkish';
import './WelcomePage.scss';

import { Information, Launch, Login, VideoChat } from '@carbon/react/icons';
import useOnlyDevStage from '../../hooks/useOnlyDevStage';
import { useQueryParam } from '../../hooks/useQueryParam';

const WelcomePage = () => {
  const { t } = useTranslation();
  const { survey, loading } = useSurvey();
  const { login, isAuthenticated } = useOidc();
  const [open, setOpen] = useState(false);
  const [modalVideoKey, setModalVideoKey] = useState(0);
  const isChina = useChina();
  const isTurkish = useTurkish();
  const isDev = useOnlyDevStage();

  const surveyId = useQueryParam('surveyId');

  const newFrontendUrl = process.env.REACT_APP_NEW_FRONTEND_URL;

  if (surveyId != null && survey == null && !loading) {
    if (localStorage.getItem(LOCAL_STORAGE_RELOAD_FLAG) === 'true') {
      window.location.reload();
      localStorage.removeItem(LOCAL_STORAGE_RELOAD_FLAG);
    }
  }

  const handleModalClose = () => {
    setOpen(false);
    setModalVideoKey(modalVideoKey + 1);
  };

  const handleLogin = () => {
    login()
      .then(() => {
        localStorage.setItem(LOCAL_STORAGE_RELOAD_FLAG, 'true');
      })
      .catch((error) => {
        console.error('Login failed:', error);
      });
  };

  const handleRegistration = () => {
    const configuration = JSON.parse(localStorage.getItem(LOCAL_STORAGE_OIDC_CONFIG_NAME) ?? '{}');

    window.open(
      `${
        configuration.authority
      }/protocol/openid-connect/registrations?client_id=cora-supplier&response_type=code&redirect_uri=${encodeURIComponent(
        window.location.href,
      )}`,
      '_self',
    );
  };

  const forwardToSupplier = () => {
    window.open(`${newFrontendUrl}/survey/${surveyId}/forwarding`, '_blank');
  };

  // Determine if Forward to Supplier option should be shown
  const showForwardOption =
    survey &&
    !loading &&
    isDev &&
    (isAuthenticated || !survey.oidc_required) &&
    ['Sent', 'Resent', 'Started', 'Reminder', 'Overdue', 'Escalated', 'dummy_status'].includes(
      survey?.status,
    );

  return (
    <div className="welcome-page">
      <Grid className="welcome-header">
        <Column lg={16} md={8} sm={4} className="survey-section">
          <h2>
            {survey ? (
              t('welcome.header', {
                importerName: survey['importer_name'],
              })
            ) : (
              <SkeletonText heading paragraph />
            )}
          </h2>
        </Column>
      </Grid>

      {/* Authentication Banner - Only show if not authenticated and self_registration is enabled */}
      {survey?.supplier_self_registration && !isAuthenticated && (
        <Grid className="auth-banner">
          <Column lg={12} md={6} sm={4}>
            <div className="auth-message">
              <Information size={24} />
              <p>
                {t(
                  'surveyInfo.authMessage',
                  'Authenticate to access additional features and track your survey submissions',
                )}
              </p>
            </div>
          </Column>
          <Column lg={4} md={2} sm={4}>
            <div className="auth-buttons">
              <Button kind="primary" renderIcon={Login} onClick={handleLogin}>
                {t('common.buttons.verifyOIDC')}
              </Button>
              <Button kind="secondary" renderIcon={Launch} onClick={handleRegistration}>
                {t('common.buttons.supplierRegistration')}
              </Button>
            </div>
          </Column>
        </Grid>
      )}

      <Grid className="main-content">
        {/* Parameter Table */}
        <Column lg={16} md={8} sm={4}>
          {survey && !loading ? (
            <ParameterTable id={surveyId ?? ''} data={survey} />
          ) : (
            <>
              <ParameterTableSkeleton isLoading={true} />
              <Loading withOverlay={true} />
            </>
          )}
        </Column>

        {/* Forward to Supplier Button */}
        {showForwardOption && (
          <Column lg={16} md={8} sm={4}>
            <Tile className="forward-supplier-tile">
              <div className="forward-supplier-content">
                <div className="forward-supplier-info">
                  <h4>{t('surveyInfo.forwardTitle', 'Are you a dealer?')}</h4>
                  <p>
                    {t(
                      'surveyInfo.forwardToSupplierText',
                      'If you do not produce the goods covered by this survey, you can forward it to your supplier.',
                    )}
                  </p>
                </div>
                <Button
                  className="forward-button"
                  kind="tertiary"
                  renderIcon={Launch}
                  onClick={forwardToSupplier}
                >
                  {t('surveyInfo.forwardToSupplierButton', 'Forward to Supplier')}
                </Button>
              </div>
            </Tile>
          </Column>
        )}

        {/* Introduction Section */}
        <Column lg={12} md={6} sm={4}>
          <Tile className="intro-tile">
            <h2>{t('surveyInfo.introTextHeader')}</h2>
            <p>{t('surveyInfo.introText')}</p>
            <Button renderIcon={VideoChat} onClick={() => setOpen(true)}>
              {t('surveyInfo.buttonText')}
            </Button>
          </Tile>
        </Column>

        <Column lg={4} md={2} sm={4}>
          <img className="factory-image" src={greenFactory} alt="Carbon illustration" />
        </Column>

        {/* Video Modal */}
        <Modal
          open={open}
          onRequestClose={handleModalClose}
          passiveModal
          modalHeading="Introduction to CBAM & CORA"
          primaryButtonText="Close Window"
        >
          <VideoEmbed
            src="https://video.ibm.com/embed/recorded/133967499"
            title="CORA Context Help - Welcome"
            width="100%"
            height="500px"
            key={modalVideoKey.toString()}
          />
        </Modal>
      </Grid>

      {/* Video Tutorial Section */}
      <Grid className="video-section">
        <Column lg={16} md={8} sm={4}>
          <div className="section-heading">
            <h3>{t('surveyInfo.walkthroughTitle')}</h3>
            <p>{t('surveyInfo.walkthroughSubtitle')}</p>
          </div>
        </Column>

        <Column lg={8} md={4} sm={4} className="full-walkthrough">
          <Tile>
            <h4>{t('surveyInfo.video11Title')}</h4>
            <div className="video-container featured-video">
              <VideoEmbed
                src={
                  isChina
                    ? 'https://video.ibm.com/embed/recorded/134112264'
                    : isTurkish
                    ? 'https://video.ibm.com/embed/recorded/134135440'
                    : 'https://video.ibm.com/embed/recorded/134063397'
                }
                title="CBAM Academy - Full Survey Walkthrough English"
                width="100%"
                height="300px"
              />
            </div>
            <div className="video-description">
              <p>{t('surveyInfo.video11Description')}</p>
            </div>
          </Tile>
        </Column>
      </Grid>

      {/* Detailed Tutorials Section */}
      <Grid className="tutorial-section">
        <Column lg={16} md={8} sm={4}>
          <div className="section-heading">
            <h3>{t('surveyInfo.tutorialsHeader')}</h3>
            <p>{t('surveyInfo.tutorialsSubHeader')}</p>
          </div>
        </Column>

        <Column lg={5} md={4} sm={4} className="video-column">
          <Tile>
            <h4>{t('surveyInfo.video1Title')}</h4>
            <div className="video-container">
              <VideoEmbed
                src={
                  isChina
                    ? 'https://video.ibm.com/embed/recorded/134265610'
                    : isTurkish
                    ? 'https://player.vimeo.com/video/1064197259'
                    : 'https://player.vimeo.com/video/990212854'
                }
                title="CBAM Academy - Reporting Periods"
                width="100%"
                height="300px"
              />
            </div>
            <div className="video-description">
              <p>{t('surveyInfo.video1Description')}</p>
            </div>
          </Tile>
        </Column>

        <Column lg={5} md={4} sm={4} className="video-column">
          <Tile>
            <h4>{t('surveyInfo.video2Title')}</h4>
            <div className="video-container">
              <VideoEmbed
                src={
                  isChina
                    ? 'https://video.ibm.com/embed/recorded/134265662'
                    : isTurkish
                    ? 'https://player.vimeo.com/video/1064210203'
                    : 'https://player.vimeo.com/video/967155093'
                }
                title="CBAM Academy - Direct Emissions"
                width="100%"
                height="300px"
              />
            </div>
            <div className="video-description">
              <p>{t('surveyInfo.video2Description')}</p>
            </div>
          </Tile>
        </Column>

        <Column lg={5} md={4} sm={4} className="video-column">
          <Tile>
            <h4>{t('surveyInfo.video3Title')}</h4>
            <div className="video-container">
              <VideoEmbed
                src={
                  isChina
                    ? 'https://video.ibm.com/embed/recorded/134260890'
                    : isTurkish
                    ? 'https://player.vimeo.com/video/1063357738'
                    : 'https://player.vimeo.com/video/990236147'
                }
                title="CBAM Academy - Indirect Emissions"
                width="100%"
                height="300px"
              />
            </div>
            <div className="video-description">
              <p>{t('surveyInfo.video3Description')}</p>
            </div>
          </Tile>
        </Column>

        <Column lg={5} md={4} sm={4} className="video-column">
          <Tile>
            <h4>{t('surveyInfo.video6Title')}</h4>
            <div className="video-container">
              <VideoEmbed
                src={
                  isChina
                    ? 'https://video.ibm.com/embed/recorded/134265708'
                    : isTurkish
                    ? 'https://player.vimeo.com/video/1064220442'
                    : 'https://player.vimeo.com/video/990240831'
                }
                title="CBAM Academy - Total Production Levels"
                width="100%"
                height="300px"
              />
            </div>
            <div className="video-description">
              <p>{t('surveyInfo.video6Description')}</p>
            </div>
          </Tile>
        </Column>
        <Column lg={5} md={4} sm={4} className="video-column">
          <Tile>
            <h4>{t('surveyInfo.video9Title')}</h4>
            <div className="video-container">
              <VideoEmbed
                src={
                  isChina
                    ? 'https://video.ibm.com/embed/recorded/134265747'
                    : isTurkish
                    ? 'https://player.vimeo.com/video/1064235414'
                    : 'https://player.vimeo.com/video/996595968'
                }
                title="CBAM Academy - Sub-Surveys"
                width="100%"
                height="300px"
              />
            </div>
            <div className="video-description">
              <p>{t('surveyInfo.video9Description')}</p>
            </div>
          </Tile>
        </Column>
      </Grid>
    </div>
  );
};

export default WelcomePage;
