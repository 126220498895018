import React from 'react';
import { useTranslation } from 'react-i18next';
import { InformationFilled, WarningFilled } from '@carbon/react/icons';

interface PurchasedEmission {
  emission_name: string;
  cn_code: number;
  activity: string | number;
  category: string;
  unit?: 't' | 'kg' | string; 
}

interface PurchasedEmissionsNotificationProps {
  purchasedEmissions: PurchasedEmission[];
  status?: string;
}

const PurchasedEmissionsNotification: React.FC<PurchasedEmissionsNotificationProps> = ({ 
  purchasedEmissions,
  status
}) => {
  const { t } = useTranslation();
  
  if (!purchasedEmissions || purchasedEmissions.length === 0) {
    return null;
  }
  
  const isWaitingStatus = status === 'waiting for sub-surveys';
  
  return (

      

      
      <div className="mt-3 p-3 bg-white rounded border border-blue-100">

      </div>
      

      

  );
};

export default PurchasedEmissionsNotification;