import React, { ReactNode } from 'react';

interface DetailItem {
  label: string;
  value: string | number | ReactNode;
  highlight?: boolean;
}

interface SummarySectionProps {
  title?: string;
  items: DetailItem[];
  highlight?: boolean;
  columns?: 1 | 2 | 3;
  className?: string;
}

const SummarySection: React.FC<SummarySectionProps> = ({ 
  title, 
  items, 
  highlight = false,
  columns = 2,
  className = ''
}) => {
  const getGridTemplateColumns = () => {
    switch (columns) {
      case 1:
        return '1fr';
      case 3:
        return 'repeat(3, 1fr)';
      case 2:
      default:
        return 'repeat(2, 1fr)';
    }
  };

  return (
    <div className={`details-section ${highlight ? 'highlight' : ''} ${className}`}>
      {title && (
        <div className="comparison-header">
          <h4>{title}</h4>
        </div>
      )}
      <div 
        className="details-content"
        style={{ gridTemplateColumns: getGridTemplateColumns() }}
      >
        {items.map((item, index) => (
          <div key={index} className={`details-item ${item.highlight ? 'highlight' : ''}`}>
            <span className="label">{item.label}</span>
            <span className="value">{item.value}</span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SummarySection;